import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLTReport } from "../../../redux/slice/LTReportSlice";
import { useAuth } from "../../../context/AuthContext";
import jwt_decode from "jwt-decode";
// import { CSVLink } from "react-csv";
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Select,
  Tag,
  Divider,
} from "antd";
import { SearchOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { setLTUpdatedOn } from "../../../redux/slice/updatedOnLTSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchFilterDetails } from "../../../redux/slice/filterSlice";
import "../../../../assets/styles/buttons.css";
import { HashLoader } from "react-spinners";

import {
  StyledTable,
  MyBtn,
  StyledCSVLink
} from "../components/styleComp";

const { Search } = Input;

function CreditPipeline(props) {
  const [branchDataSource, setBranchDataSource] = useState([]);
  const [vendorDataSource, setVendorDataSource] = useState([]);
  const [stageDataSource, setStageDataSource] = useState([]);
  const [casesTotal, setCasesTotal] = useState();
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [detailedLoading, setDetailedLoading] = useState(true);
  const [selectedStage, setSelectedStage] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [showMoreModalVisible, setShowMoreModalVisible] = useState(false);
  const [showMoreData, setShowMoreData] = useState({});

  const dispatch = useDispatch();

  const uniquePendingFor = [
    ...new Set(branchDataSource.map((item) => item.PENDING_FOR)),
  ];
  const uniqueBranch = [...new Set(branchDataSource.map((item) => item.BR_CD))];
  uniqueBranch.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueType = [...new Set(branchDataSource.map((item) => item.TYPE))];
  const uniqueAppID = [...new Set(branchDataSource.map((item) => item.APP_ID))];
  const uniqueRepeatAppID = [
    ...new Set(branchDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueSupervisor = [
    ...new Set(branchDataSource.map((item) => item.SUPERVISOR)),
  ];
  const uniqueAppName = [
    ...new Set(branchDataSource.map((item) => item.APPLICANT_NAME)),
  ];

  const uniqueProduct = [
    ...new Set(branchDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusType = [
    ...new Set(branchDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1 = [
    ...new Set(branchDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatus = [
    ...new Set(branchDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntity = [
    ...new Set(branchDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadhar = [
    ...new Set(branchDataSource.map((item) => item.UDYAM_AADHAR)),
  ];
  const branchColumns = [
    {
      title: <strong style={{ fontSize: "18px" }}>Pending For</strong>,
      dataIndex: "PENDING_FOR",
      key: "PENDING_FOR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniquePendingFor.map((PENDING_FOR) => ({
        text: PENDING_FOR,
        value: PENDING_FOR,
      })),
      onFilter: (value, record) => record.PENDING_FOR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranch.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "SUPERVISOR",
      key: "SUPERVISOR",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisor.map((SUPERVISOR) => ({
        text: SUPERVISOR,
        value: SUPERVISOR,
      })),
      onFilter: (value, record) => record.SUPERVISOR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueType.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniqueAppID.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniqueRepeatAppID.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppName.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProduct.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Businee Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Businee Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatus.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntity.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadhar.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniquePendingForVendor = [
    ...new Set(vendorDataSource.map((item) => item.PENDING_FOR)),
  ];
  const uniqueBranchVendor = [
    ...new Set(vendorDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchVendor.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueTypeVendor = [
    ...new Set(vendorDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDVendor = [
    ...new Set(vendorDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDVendor = [
    ...new Set(vendorDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueSupervisorVendor = [
    ...new Set(vendorDataSource.map((item) => item.SUPERVISOR)),
  ];
  const uniqueAppNameVendor = [
    ...new Set(vendorDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueProductVendor = [
    ...new Set(vendorDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypeVendor = [
    ...new Set(vendorDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1Vendor = [
    ...new Set(vendorDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusVendor = [
    ...new Set(vendorDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityVendor = [
    ...new Set(vendorDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharVendor = [
    ...new Set(vendorDataSource.map((item) => item.UDYAM_AADHAR)),
  ];

  const csvHeaders = [
    { label: "PENDING FOR", key: "PENDING_FOR" },
    { label: "BRANCH", key: "BR_CD" },
    { label: "SUPERVISOR", key: "SUPERVISOR" },
    { label: "LOAN TYPE", key: "TYPE" },
    { label: "APP ID", key: "APP_ID" },
    { label: "REPEAT APP ID", key: "REPEAT_APP_ID" },
    { label: "APPLICANT_NAME", key: "APPLICANT_NAME" },
    { label: "PRODUCT", key: "PRODUCT" },
    { label: "BUSINESS TYPE", key: "BUSINESS_TYPE" },
    { label: "BUSINESS TYPE 1", key: "BUSINESS_TYPE_1" },
    { label: "PIPELINE STATUS", key: "PIPELINE_STATUS" },
    { label: "INS ENTITY", key: "INS_ENTITY" },
    { label: "UDYAM AADHAR", key: "UDYAM_AADHAR" },
    { label: "APPLIED LOAN AMT", key: "REQ_LOAN_AMT" },
    { label: "CURRENT ACTIVITY", key: "CURR_STATUS" },
    { label: "CURR STATUS UPDATED", key: "CURR_STATUS_UPDATED" },
    { label: "LEGAL QUERY", key: "LEGAL_QUERY" },
    { label: "TECHNICAL QUERY", key: "TECHNICAL_QUERY" },
    { label: "APP TECHNICAL STATUS", key: "APP_TECHNICAL_STATUS" },
    { label: "APP LEGAL STATUS", key: "APP_LEGAL_STATUS" },
    {
      label: "BUSINESS VERIFICATION COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
    },
    {
      label: "CREDIT DECISIONING COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
    },
  ];

  const vendorColumns = [
    {
      title: <strong style={{ fontSize: "18px" }}>Pending For</strong>,
      dataIndex: "PENDING_FOR",
      key: "PENDING_FOR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniquePendingForVendor.map((PENDING_FOR) => ({
        text: PENDING_FOR,
        value: PENDING_FOR,
      })),
      onFilter: (value, record) => record.PENDING_FOR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchVendor.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "SUPERVISOR",
      key: "SUPERVISOR",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorVendor.map((SUPERVISOR) => ({
        text: SUPERVISOR,
        value: SUPERVISOR,
      })),
      onFilter: (value, record) => record.SUPERVISOR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypeVendor.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniqueAppIDVendor.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniqueRepeatAppIDVendor.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNameVendor.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductVendor.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypeVendor.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1Vendor.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusVendor.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityVendor.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharVendor.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];
  const { authState, onLogout } = useAuth();
  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails.group_id;
    console.log("JWT Group Reports - ", jwt_payload_group);
  }

  console.log("JWT Bearer LT - ", jwtBearer);

  useEffect(() => {
    // Dispatch both fetch actions concurrently
    Promise.all([
      dispatch(fetchLTReport({ jwtBearer })),
      dispatch(fetchFilterDetails()), // Dispatch fetchFilterDetails as well
    ]).then(() => {
      setLoading(false); // Set loading state to false when both fetch actions are completed
    });
  }, []);
  // useEffect(() => {
  //   dispatch(fetchLTReport({ jwtBearer })).then(() => {
  //     setLoading(false) // Set loading state to false when the data is fetched
  //   })
  // }, [])

  const state = useSelector((state) => state);
  console.log("LT Pipeline State - ", state);

  const tokenExpired = state.LTReports.data?.detail || "";
  const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition");
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  const allFields =
    (state.LTReports.data && state.LTReports.data.message) || [];
  const updatedOn = state.LTReports.data?.updated_on || "";
  dispatch(setLTUpdatedOn(updatedOn));

  // console.log('Supervisor Updated On - ', updatedOn)
  console.log("LT Pipeline All Fields - ", allFields);

  const branchFilter =
    (state.filter.data && state.filter.data.branch_data) || [];

  console.log("Filter Data Response - ", branchFilter);

  const branchZoneFilter = branchFilter.zone;
  const branchStateFilter = branchFilter.state;
  const branchSupervisorFilter = branchFilter.supervisor_name;
  const branchDetailsFilter = branchFilter.branch_details;

  useEffect(() => {
    const fieldArrays = Object.values(allFields);
    console.log("Fields Array", fieldArrays);

    if (fieldArrays.length > 0) {
      // const numRows = fieldArrays.length
      const numRows = Object.keys(fieldArrays[0]).length;
      console.log("LT num rows", numRows);
      const formattedVendorData = [];
      const formattedBranchData = [];

      for (let i = 0; i < numRows; i++) {
        // console.log('In for loop initial')
        const data = {};
        for (let j = 0; j < fieldArrays.length; j++) {
          // console.log('In for loop 2 initial')
          const fieldName = Object.keys(allFields)[j];
          if (fieldName == "PENDING_FOR") {
            // console.log('In pending for condition')
            if (fieldArrays[j][i] > 24) {
              data[fieldName] =
                fieldArrays[j][i] +
                " HRS - " +
                "(" +
                Math.floor(fieldArrays[j][i] / 24) +
                " Days)";
            }
          } else if (fieldName == "LEGAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              try {
                console.log("Issue in code - ", fieldArrays[j][i]);
                console.log(
                  "Latest Feedback In legal query condition",
                  JSON.parse(fieldArrays[j][i])
                );
                const FeedbackData = JSON.parse(fieldArrays[j][i]);
                // const showMoreLink = '<a href="#" onclick="showMoreFeedback()">show more</a>';
                if (FeedbackData && Array.isArray(FeedbackData)) {
                  FeedbackData.sort(
                    (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                  );
                  const latestFeedback = FeedbackData[0].Feedback;
                  console.log("Latest Feedback - ", latestFeedback);
                  const feedbackContent = showMore
                    ? latestFeedback
                    : `${latestFeedback} `;
                  const showMoreLink = (
                    <a
                      href="#"
                      onClick={() => handleShowMoreClick(FeedbackData)}
                    >
                      show more
                    </a>
                  );
                  // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                  data[fieldName] = (
                    <>
                      {feedbackContent}
                      {showMoreLink}
                    </>
                  );
                  // data[fieldName] = latestFeedback
                  // data[fieldName] = `${latestFeedback} <a href="#" onclick="showMoreFeedback()">show more</a>`;
                } else {
                  console.log(
                    "Invalid data format. Expected a non-null array."
                  );
                }
              } catch (e) {
                console.log("Exception in code - ", e, fieldArrays[j][i]);
              }
            }
          } else if (fieldName == "TECHNICAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldArrays[j][i])
              );
              const FeedbackData = JSON.parse(fieldArrays[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
            // console.log('In technical query condition')
          } else {
            data[fieldName] = fieldArrays[j][i];
          }
        }
        console.log("In for loop", data["CURR_STATUS"]);

        if (
          data["CURR_STATUS"] === "LEGAL ANALYSIS" ||
          data["CURR_STATUS"] === "TECHNICAL ANALYSIS" ||
          data["CURR_STATUS"] === "LEGAL ANALYSIS, TECHNICAL ANALYSIS"
        ) {
          console.log("In Vendor condition");
          formattedVendorData.push(data);
        } else if (
          data["CURR_STATUS"] === "LEGAL QUERY" ||
          data["CURR_STATUS"] === "TECHNICAL QUERY" ||
          data["CURR_STATUS"] === "LEGAL QUERY, TECHNICAL QUERY"
        ) {
          console.log("In Branch condition");
          formattedBranchData.push(data);
        }
      }
      console.log("LT Branch Data", formattedBranchData);
      console.log("LT Vendor Data", formattedVendorData);

      setBranchDataSource(formattedBranchData);
      setVendorDataSource(formattedVendorData);
      console.log("L&T all datasource - ", formattedVendorData);
    }
  }, [allFields]);

  // useEffect(() => {
  //   // if (allFields && allFields.stage && allFields.value) {
  //   if (allFields && allFields.APP_ID) {
  //     const fieldArrays = Object.keys(allFields.APP_ID).map(key => ({
  //       PENDING_FOR: allFields.PENDING_FOR[key] + ' HRS',
  //       // BR_CD: allFields.BR_CD[key],
  //       SUPERVISOR: allFields.SUPERVISOR[key],
  //       APP_ID: allFields.APP_ID[key],
  //       APPLICANT_NAME: allFields.APPLICANT_NAME[key],
  //       REQ_LOAN_AMT: allFields.REQ_LOAN_AMT[key],
  //       CURR_STATUS: allFields.CURR_STATUS[key],
  //       CURR_STATUS_UPDATED: allFields.CURR_STATUS_UPDATED[key]
  //     }))

  //     setDataSource(fieldArrays)
  //   }
  // }, [allFields])

  console.log("LT Datasource - ", branchDataSource);

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const [selectedZone, setSelectedZone] = useState("all");
  const [selectedState, setSelectedState] = useState("all");
  const [selectedSupervisor, setSelectedSupervisor] = useState("all");
  const [selectedBranch, setSelectedBranch] = useState("all");
  const [selectedProduct, setSelectedProduct] = useState("all");
  const [selectedBranchDetails, setSelectedBranchDetails] = useState([]);

  const handleFilterReset = () => {
    // Reset filter value
    const uniqueValues = [...new Set(Object.values(branchStateFilter))];
    const uniqueValuesSupervisor = [
      ...new Set(Object.values(branchSupervisorFilter)),
    ];
    const uniqueValuesBranches = [
      ...new Set(Object.values(branchDetailsFilter)),
    ];
    setFilteredStates(uniqueValues);
    setFilteredSupervisors(uniqueValuesSupervisor);
    setFilteredBranch(uniqueValuesBranches);
    // Reset filter value

    setSelectedZone("all");
    setSelectedState("all");
    setSelectedSupervisor("all");
    setSelectedBranch("all");

    const fieldArrays = Object.values(allFields);

    console.log("LT all fields fields array - ", fieldArrays);

    const numRows = Object.keys(fieldArrays[0]).length;
    console.log("LT num rows", numRows);
    const formattedVendorData = [];
    const formattedBranchData = [];

    for (let i = 0; i < numRows; i++) {
      console.log("In for loop");
      const data = {};
      for (let j = 0; j < fieldArrays.length; j++) {
        const fieldName = Object.keys(allFields)[j];
        console.log("All Fields", fieldName);
        if (fieldName == "PENDING_FOR") {
          console.log("Reset if 1 - ", fieldArrays[j][i]);
          if (fieldArrays[j][i] > 24) {
            console.log("Reset if 2 - ", fieldArrays[j][i]);
            data[fieldName] =
              fieldArrays[j][i] +
              " HRS - " +
              "(" +
              Math.floor(fieldArrays[j][i] / 24) +
              " Days)";
          } else {
            data[fieldName] = fieldArrays[j][i] + " HRS";
            console.log("Reset else 1 - ", fieldArrays[j][i]);
          }
        } else if (fieldName == "LEGAL_QUERY") {
          if (fieldArrays[j][i] != "NULL") {
            console.log(
              "Latest Feedback In legal query condition",
              JSON.parse(fieldArrays[j][i])
            );
            const FeedbackData = JSON.parse(fieldArrays[j][i]);
            if (FeedbackData && Array.isArray(FeedbackData)) {
              FeedbackData.sort(
                (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
              );
              const latestFeedback = FeedbackData[0].Feedback;
              console.log("Latest Feedback - ", latestFeedback);
              // data[fieldName] = latestFeedback
              const feedbackContent = showMore
                ? latestFeedback
                : `${latestFeedback} `;
              const showMoreLink = (
                <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                  show more
                </a>
              );
              // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
              data[fieldName] = (
                <>
                  {feedbackContent}
                  {showMoreLink}
                </>
              );
            } else {
              console.log("Invalid data format. Expected a non-null array.");
            }
          }
        } else if (fieldName == "TECHNICAL_QUERY") {
          if (fieldArrays[j][i] != "NULL") {
            console.log(
              "Latest Feedback In legal query condition",
              JSON.parse(fieldArrays[j][i])
            );
            const FeedbackData = JSON.parse(fieldArrays[j][i]);
            if (FeedbackData && Array.isArray(FeedbackData)) {
              FeedbackData.sort(
                (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
              );
              const latestFeedback = FeedbackData[0].Feedback;
              console.log("Latest Feedback - ", latestFeedback);
              // data[fieldName] = latestFeedback
              const feedbackContent = showMore
                ? latestFeedback
                : `${latestFeedback} `;
              const showMoreLink = (
                <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                  show more
                </a>
              );
              // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
              data[fieldName] = (
                <>
                  {feedbackContent}
                  {showMoreLink}
                </>
              );
            } else {
              console.log("Invalid data format. Expected a non-null array.");
            }
          }
          // console.log('In technical query condition')
        } else {
          console.log("Reset else 2 - ", fieldArrays[j][i]);
          data[fieldName] = fieldArrays[j][i];
        }
      }
      console.log("In for loop", data["CURR_STATUS"]);

      if (
        data["CURR_STATUS"] === "LEGAL ANALYSIS" ||
        data["CURR_STATUS"] === "TECHNICAL ANALYSIS" ||
        data["CURR_STATUS"] === "LEGAL ANALYSIS, TECHNICAL ANALYSIS"
      ) {
        console.log("In Vendor condition");
        formattedVendorData.push(data);
      } else if (
        data["CURR_STATUS"] === "LEGAL QUERY" ||
        data["CURR_STATUS"] === "TECHNICAL QUERY" ||
        data["CURR_STATUS"] === "LEGAL QUERY, TECHNICAL QUERY"
      ) {
        console.log("In Branch condition");
        formattedBranchData.push(data);
      }
    }
    console.log("LT Branch Data", formattedBranchData);
    console.log("LT Vendor Data", formattedVendorData);

    setBranchDataSource(formattedBranchData);
    setVendorDataSource(formattedVendorData);
  };

  const handleFilterChange = () => {
    let filteredBranchDetails = [];
    let filteredBranchDetailsZone = [];
    let filteredBranchDetailsState = [];
    let filteredBranchDetailsSupervisor = [];
    let filteredBranchDetailsBranch = [];
    let filteredData;

    if (
      selectedZone == "all" &&
      selectedState == "all" &&
      selectedSupervisor == "all" &&
      selectedBranch == "all"
    ) {
      const fieldArrays = Object.values(allFields);
      console.log("All obj", fieldArrays);
      const numRows = Object.keys(fieldArrays[0]).length;
      console.log("LT num rows", numRows);
      const formattedVendorData = [];
      const formattedBranchData = [];

      for (let i = 0; i < numRows; i++) {
        console.log("In for loop");
        const data = {};
        for (let j = 0; j < fieldArrays.length; j++) {
          const fieldName = Object.keys(allFields)[j];
          if (fieldName == "PENDING_FOR") {
            if (fieldArrays[j][i] > 24) {
              data[fieldName] =
                fieldArrays[j][i] +
                " HRS - " +
                "(" +
                Math.floor(fieldArrays[j][i] / 24) +
                " Days)";
            } else {
              data[fieldName] = fieldArrays[j][i] + " HRS";
            }
          } else if (fieldName == "LEGAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldArrays[j][i])
              );
              const FeedbackData = JSON.parse(fieldArrays[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
          } else if (fieldName == "TECHNICAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldArrays[j][i])
              );
              const FeedbackData = JSON.parse(fieldArrays[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
            // console.log('In technical query condition')
          } else {
            data[fieldName] = fieldArrays[j][i];
          }
        }
        console.log("In for loop", data["CURR_STATUS"]);

        if (
          data["CURR_STATUS"] === "LEGAL ANALYSIS" ||
          data["CURR_STATUS"] === "TECHNICAL ANALYSIS" ||
          data["CURR_STATUS"] === "LEGAL ANALYSIS, TECHNICAL ANALYSIS"
        ) {
          console.log("In Vendor condition");
          formattedVendorData.push(data);
        } else if (
          data["CURR_STATUS"] === "LEGAL QUERY" ||
          data["CURR_STATUS"] === "TECHNICAL QUERY" ||
          data["CURR_STATUS"] === "LEGAL QUERY, TECHNICAL QUERY"
        ) {
          console.log("In Branch condition");
          formattedBranchData.push(data);
        }
      }
      console.log("LT Branch Data", formattedBranchData);
      console.log("LT Vendor Data", formattedVendorData);

      setBranchDataSource(formattedBranchData);
      setVendorDataSource(formattedVendorData);
    } else {
      filteredBranchDetails = Object.entries(branchFilter.branch_details)
        .filter(([_, branch_details]) => {
          return (
            (selectedZone === "all" || branchFilter.zone[_] === selectedZone) &&
            (selectedState === "all" ||
              branchFilter.state[_] === selectedState) &&
            (selectedSupervisor === "all" ||
              branchFilter.supervisor_name[_] === selectedSupervisor) &&
            (selectedBranch === "all" || branch_details === selectedBranch)
          );
        })
        .map(([index]) => branchFilter.branch_details[index].split("-")[0]);

      console.log("LT filtered branch list", filteredBranchDetails);
      console.log("LT filtered branch list allfields", allFields);

      // const formattedData = Object.keys(allFields).reduce((result, key) => {
      //   result[key] = filteredBranchDetails.reduce(
      //     (branchData, branchId, index) => {
      //       const dataIndex = Object.values(allFields.BRANCH_ID).indexOf(
      //         branchId
      //       )
      //       if (dataIndex !== -1) {
      //         branchData[index] = allFields[key][dataIndex]
      //       }
      //       return branchData
      //     },
      //     {}
      //   )

      //   return result
      // }, {})
      const formattedData = Object.keys(allFields).reduce((result, key) => {
        result[key] = {};

        Object.keys(allFields[key]).forEach((index) => {
          const branchId = allFields.BRANCH_ID[index];
          if (filteredBranchDetails.includes(branchId)) {
            result[key][index] = allFields[key][index];
          }
        });

        return result;
      }, {});

      // const formattedData = Object.keys(allFields).reduce((result, key) => {
      //   result[key] = {}

      //   Object.keys(allFields[key]).forEach(index => {
      //     const branchId = allFields.BRANCH_ID[index]
      //     if (filteredBranchDetails.includes(branchId)) {
      //       if (!result[key].hasOwnProperty(index)) {
      //         result[key][index] = {}
      //       }
      //       result[key][index] = allFields[key][index]
      //     }
      //   })

      //   return result
      // }, {})

      console.log("LT filtered all data - ", formattedData);

      const filteredObj = Object.values(formattedData);
      console.log("Filtered Obj - ", filteredObj[0][6]);

      const numRows = Object.keys(filteredObj[0]).length;
      console.log("LT num rows", numRows);
      const formattedVendorData = [];
      const formattedBranchData = [];
      const formattedAllData = [];

      // Assuming formattedData, formattedAllData, formattedVendorData, and formattedBranchData are defined

      for (let i = 0; i < numRows; i++) {
        console.log("In for loop");
        const data = {};

        for (let j = 0; j < filteredObj.length; j++) {
          console.log("In for loop 2");
          const fieldValues = Object.values(filteredObj[j]); // Use Object.values() here
          const fieldName = Object.keys(formattedData)[j];
          console.log("Field name - ", fieldName);

          if (fieldName === "PENDING_FOR") {
            console.log("Filter obj if 1 - ", fieldValues[i]);
            if (fieldValues[i] > 24) {
              console.log("Filter obj if 2 - ", fieldValues[i]);
              data[fieldName] =
                fieldValues[i] +
                " HRS - " +
                "(" +
                Math.floor(fieldValues[i] / 24) +
                " Days)";
            } else {
              console.log("Filter obj else 1 - ", fieldValues[i]);
              data[fieldName] = fieldValues[i] + " HRS";
            }
          } else if (fieldName == "LEGAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldArrays[j][i])
              );
              const FeedbackData = JSON.parse(fieldArrays[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
          } else if (fieldName == "TECHNICAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldArrays[j][i])
              );
              const FeedbackData = JSON.parse(fieldArrays[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
            // console.log('In technical query condition')
          } else {
            console.log("Filter obj else 2 - ", fieldValues[i]);
            data[fieldName] = fieldValues[i];
          }
        }

        console.log("In for loop data", data);
        formattedAllData.push(data);
        console.log("LT all filtered data", formattedAllData);

        if (
          data["CURR_STATUS"] === "LEGAL ANALYSIS" ||
          data["CURR_STATUS"] === "TECHNICAL ANALYSIS" ||
          data["CURR_STATUS"] === "LEGAL ANALYSIS, TECHNICAL ANALYSIS"
        ) {
          console.log("In Vendor condition");
          formattedVendorData.push(data);
        } else if (
          data["CURR_STATUS"] === "LEGAL QUERY" ||
          data["CURR_STATUS"] === "TECHNICAL QUERY" ||
          data["CURR_STATUS"] === "LEGAL QUERY, TECHNICAL QUERY"
        ) {
          console.log("In Branch condition");
          formattedBranchData.push(data);
        }
      }

      console.log("LT Branch Data", formattedBranchData);
      console.log("LT Vendor Data", formattedVendorData);

      setBranchDataSource(formattedBranchData);
      setVendorDataSource(formattedVendorData);
    }
  };

  const handleShowMoreClick = (e) => {
    setShowMoreModalVisible(true);
    setShowMoreData(e);
    console.log("Show more on click", e);
  };

  const handleCancel = () => {
    setShowMoreModalVisible(false);
  };

  // const handleZoneChange = value => {
  //   setSelectedZone(value)
  //   if (value != 'all') {
  //     console.log('In zone filter State condition')
  //     // const filteredStateonZone = Object.entries(branchFilter.zone)
  //     branchStateFilter = Object.entries(branchFilter.zone)
  //       .filter(([_, zone]) => zone === value)
  //       .map(([index]) => branchFilter.state[index].split('-')[0])
  //     console.log('Zone filtered States - ', branchStateFilter)
  //   }
  // }

  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredSupervisors, setFilteredSupervisors] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);

  console.log("If branchStateFilter is defined - ", branchStateFilter);

  const handleZoneChange = (value) => {
    setSelectedZone(value);
    if (selectedState != "all") {
      setSelectedState("all");
    }
    if (selectedSupervisor != "all") {
      setSelectedSupervisor("all");
    }
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    if (value === "all") {
      console.log("In zone all condition");
      const uniqueValues = [...new Set(Object.values(branchStateFilter))];
      const uniqueValuesSupervisor = [
        ...new Set(Object.values(branchSupervisorFilter)),
      ];
      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredStates(uniqueValues);
      setFilteredSupervisors(uniqueValuesSupervisor);
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const statesForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchStateFilter[index] : null
            )
            .filter((state) => state !== null)
        ),
      ];

      const supervisorsForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchSupervisorFilter[index] : null
            )
            .filter((supervisor_name) => supervisor_name !== null)
        ),
      ];

      const branchesForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredStates(statesForSelectedZone);
      setFilteredSupervisors(supervisorsForSelectedZone);
      setFilteredBranch(branchesForSelectedZone);
    }
  };

  const handleStateChange = (value) => {
    if (selectedSupervisor != "all") {
      setSelectedSupervisor("all");
    }
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    setSelectedState(value);

    if (value === "all") {
      console.log("In branch all condition");
      const uniqueValuesSupervisor = [
        ...new Set(Object.values(branchSupervisorFilter)),
      ];
      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredSupervisors(uniqueValuesSupervisor);
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const supervisorsForSelectedState = [
        ...new Set(
          Object.values(branchStateFilter)
            .map((state, index) =>
              state === value ? branchSupervisorFilter[index] : null
            )
            .filter((supervisor_name) => supervisor_name !== null)
        ),
      ];

      const branchesForSelectedState = [
        ...new Set(
          Object.values(branchStateFilter)
            .map((state, index) =>
              state === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredSupervisors(supervisorsForSelectedState);
      setFilteredBranch(branchesForSelectedState);
    }
  };

  const handleSupervisorChange = (value) => {
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    setSelectedSupervisor(value);

    if (value === "all") {
      console.log("In supervisor all condition");

      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const branchesForSelectedSupervisor = [
        ...new Set(
          Object.values(branchSupervisorFilter)
            .map((supervisor_name, index) =>
              supervisor_name === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredBranch(branchesForSelectedSupervisor);
    }
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value);
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
  };

  const filterDataSource = () => {
    let filteredData = dataSource;

    if (selectedStage !== "all") {
      filteredData = filteredData.filter(
        (item) => item.STAGE === Number(selectedStage)
      );
    }

    if (selectedProduct !== "all") {
      filteredData = filteredData.filter(
        (item) => item.PRODUCT === selectedProduct
      );
    }

    if (selectedStatus !== "all") {
      filteredData = filteredData.filter(
        (item) => item.CURR_STATUS === selectedStatus
      );
    }

    if (selectedMobile !== "") {
      filteredData = filteredData.filter((item) =>
        item.APPLICANT_MOBILE.includes(selectedMobile)
      );
    }

    return filteredData;
  };

  return (
    <>
      {jwt_payload_group == "1" || jwt_payload_group == "3" ? null : (
        <div
          className="filters"
          style={{
            marginBottom: 20,
            display: "flex",
            flexWrap: "wrap",
            gap: 15,
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>Zone: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Zone"
              value={selectedZone}
              onChange={handleZoneChange}
            // onChange={handleFilterChange(value, 'all', 'all', 'all')}
            >
              <Option value="all">All</Option>
              {branchZoneFilter &&
                Object.values(branchZoneFilter)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((zone, index) => (
                    <Option key={index} value={zone}>
                      {zone}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>State: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select State"
              value={selectedState}
              onChange={handleStateChange}
            >
              <Option value="all">All</Option>
              {filteredStates.length !== 0
                ? filteredStates.map((state, index) => (
                  <Option key={index} value={state}>
                    {state}
                  </Option>
                ))
                : branchStateFilter &&
                Object.values(branchStateFilter)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )
                  .map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
            </Select>

            {/* <Select
           style={{ width: '100%' }}
          placeholder='Select State'
          value={selectedState}
          onChange={handleStateChange}
          // onChange={handleFilterChange('all', value, 'all', 'all')}
        >
          <Option value='all'>All</Option>
          {branchStateFilter &&
            Object.values(branchStateFilter)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((state, index) => (
                <Option key={index} value={state}>
                  {state}
                </Option>
              ))}
        </Select> */}
          </div>

          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>Supervisor: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Supervisor"
              value={selectedSupervisor}
              onChange={handleSupervisorChange}
            >
              <Option value="all">All</Option>
              {filteredSupervisors.length !== 0
                ? filteredSupervisors.map((supervisor_name, index) => (
                  <Option key={index} value={supervisor_name}>
                    {supervisor_name}
                  </Option>
                ))
                : branchSupervisorFilter &&
                Object.values(branchSupervisorFilter)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )
                  .map((supervisor_name, index) => (
                    <Option key={index} value={supervisor_name}>
                      {supervisor_name}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>Branch: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Branch"
              value={selectedBranch}
              onChange={handleBranchChange}
            >
              <Option value="all">All</Option>
              {filteredBranch.length !== 0
                ? filteredBranch.map((branch_details, index) => (
                  <Option key={index} value={branch_details}>
                    {branch_details}
                  </Option>
                ))
                : branchDetailsFilter &&
                Object.values(branchDetailsFilter)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )
                  .map((branch_details, index) => (
                    <Option key={index} value={branch_details}>
                      {branch_details}
                    </Option>
                  ))}
            </Select>
            {/* <Select
           style={{ width: '100%' }}
          placeholder='Select Branch'
          value={selectedBranch}
          onChange={handleBranchChange}
        >
          <Option value='all'>All</Option>
          {branchDetailsFilter &&
            Object.values(branchDetailsFilter)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((branch_details, index) => (
                <Option key={index} value={branch_details}>
                  {branch_details}
                </Option>
              ))}
        </Select> */}
          </div>

          <div style={{ display: "flex", gap: 50, marginTop: 10 }}>
            <MyBtn

              onClick={handleFilterChange}

              style={{
                width: 100,
                height: 40,
                margin: "auto",


              }}
            >
              Apply Filter
            </MyBtn>
            <button
              onClick={handleFilterReset}
              size="medium"
              style={{
                borderRadius: "20px",
                background: "white",
                color: "black",
                fontWeight: "600",
                padding: "0 1.2rem",
                color: "blue",
                border: "solid blue 2px",
                fontSize: "16px",
                cursor: "pointer"
              }}
            >
              Reset
            </button>{" "}
          </div>
          {/* <label>
          <strong>Product: </strong>
        </label>
        <Select
           style={{ width: '100%' }}
          placeholder='Select Product'
          value={selectedProduct}
          onChange={handleProductChange}
        >
          <Option value='all'>All</Option>
          {productOptions}
        </Select> */}
        </div>
      )}
      <div
        className="tabled"
        style={{
          height: "100vh",
          overflowY: "auto",
          marginTop: "10px",
          // background: 'white'
        }}
      >
        <Card
          layout="horizontal"
          bordered={true}
          className="criclebox tablespace mb-24"
          title="CASES PENDING WITH EXTERNAL VENDOR"
          centered
          style={{ width: "100%" }}
          extra={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              {/* {jwt_payload_group == '2' || jwt_payload_group == '3' ? null : ( */}
              <StyledCSVLink

                data={vendorDataSource}
                headers={csvHeaders}
                // headers={vendorColumns}
                onClick={() => { }}
              >
                Download Report
              </StyledCSVLink>
              {/* )} */}
            </div>
          }
        >
          <div className="table-responsive">
            {loading ? (
              <div className="spinner-container">
                {/* <Spin size='large' /> */}
                <HashLoader color="#f57171" />
              </div>
            ) : (
              <StyledTable
                // onRow={(record, rowIndex) => ({
                //   onClick: () => handleRowClick(record)
                // })}
                columns={vendorColumns}
                dataSource={vendorDataSource}
                pagination={paginationOptions}
              />
            )}
          </div>
        </Card>
        <Card
          layout="horizontal"
          bordered={true}
          className="criclebox tablespace mb-24"
          title="CASES PENDING WITH BRANCHES"
          centered
          style={{ width: "100%" }}
          extra={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              {
                // jwt_payload_group == '2' || jwt_payload_group == '3' ? null : (
                <StyledCSVLink

                  data={branchDataSource}
                  headers={csvHeaders}
                  onClick={() => { }}
                >
                  Download Report
                </StyledCSVLink>
                // )
              }
            </div>
          }
        >
          <div className="table-responsive">
            {loading ? (
              <div className="spinner-container">
                {/* <Spin size='large' /> */}
                <HashLoader color="#f57171" />
              </div>
            ) : (
              <StyledTable
                // onRow={(record, rowIndex) => ({
                //   onClick: () => handleRowClick(record)
                // })}
                columns={branchColumns}
                dataSource={branchDataSource}
                pagination={paginationOptions}
              />
            )}
          </div>
        </Card>
        <Modal
          visible={showMoreModalVisible}
          onCancel={handleCancel}
          width={1300}
          footer={null}
        >
          <div
            style={{
              maxHeight: "850px", // Adjust the max height as needed
              overflowY: "auto",
            }}
          >
            {showMoreData?.length > 0 && (
              <p>
                <strong>LEGAL QUERY TRAIL</strong>
                <br />
                <br />
              </p>
            )}
            {showMoreData?.length > 0 &&
              showMoreData.map((trailItem, index) => (
                <div key={index}>
                  <Tag color="blue">{trailItem.DateTime}</Tag> <br /> <br />
                  {/* <ArrowRightOutlined />
              <Tag color='blue' style={{marginLeft: 8}}>{trailItem.Feedback}</Tag>
              <Tag color='green' style={{marginLeft: 8}}>{trailItem.Feedback}</Tag> */}
                  <strong>Stage:</strong>{" "}
                  <div
                    style={{
                      maxHeight: "90px", // Adjust the max height as needed
                      maxWidth: "800px",
                      marginTop: "10px",
                      overflowY: "auto",
                      border: "1px solid #e8e8e8",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {trailItem.Stage}
                  </div>{" "}
                  <br />
                  <strong>Feedback: </strong>
                  <div
                    style={{
                      maxHeight: "90px", // Adjust the max height as needed
                      maxWidth: "800px",
                      marginTop: "10px",
                      overflowY: "auto",
                      border: "1px solid #e8e8e8",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {trailItem.Feedback}
                  </div>
                  <Divider />
                </div>
              ))}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default CreditPipeline;
