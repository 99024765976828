import React, { useEffect, useState, useRef } from "react";
import { View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchReport } from "../../../redux/slice/branchReportSlice";
import { useAuth } from "../../../context/AuthContext";
import jwt_decode from "jwt-decode";
import "../../../../assets/styles/buttons.css";
import {
  Spin,
  Table,
  Card,
  Button,
  Row,
  Col,
  Input,
  Space,
  Select,
  Collapse,
  Modal,
  Tag,
  Divider,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { setUpdatedOn } from "../../../redux/slice/updatedOnSlice";
import { fetchFilterDetails } from "../../../redux/slice/filterSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { CSVLink } from "react-csv";
import { HashLoader } from "react-spinners";

import { StyledModal, StyledCard, StyledTable, MyBtn, StyledCSVLink } from "../components/styleComp";

// function PipelineTable ({ onUpdatedOnChange }) {
function PipelineTable(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [showMoreModalVisible, setShowMoreModalVisible] = useState(false);
  const [showMoreData, setShowMoreData] = useState({});

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              height: 40,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              height: 40,
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("BA"),
      // filters: [
      //   {
      //     text: 'London',
      //     value: 'London'
      //   },
      //   {
      //     text: 'New York',
      //     value: 'New York'
      //   }
      // ],
      // onFilter: (value, record) => record.BA === value,
      // filterSearch: true
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch ID</strong>,
      dataIndex: "BRANCH_ID",
      key: "BRANCH_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("BRANCH_ID"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("TYPE"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("APP_ID"),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("REPEAT_APP_ID"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("APPLICANT_NAME"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("APPLICANT_MOBILE"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("PRODUCT"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("BUSINESS_TYPE"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("BUSINESS_TYPE_1"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("PIPELINE_STATUS"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("INS_ENTITY"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("UDYAM_AADHAR"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("REQ_LOAN_AMT"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("CURR_STATUS"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("CURR_STATUS_UPDATED"),
    },
  ];

  const { authState } = useAuth();
  const { onLogout } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  console.log("JWT Token Branch - ", token_decoded);
  var jwt_payload = "";
  var jwt_payload_group = [];
  if (token_decoded) {
    jwt_payload = jwt_decode(token_decoded);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails.group_id;
    console.log("JWT Group Sidenav", jwt_payload_group);
  }

  const onChange = (e) => console.log(`radio checked:${e.target.value}`);

  const [dataSource, setDataSource] = useState([]);
  const [supervisorDataSource, setSupervisorDataSource] = useState([]);
  const [creditDataSource, setCreditDataSource] = useState([]);
  const [vendorDataSource, setVendorDataSource] = useState([]);
  const [operationsDataSource, setOperationsDataSource] = useState([]);
  const [paymentPendingDataSource, setPaymentPendingDataSource] = useState([]);
  const [paymentDoneDataSource, setPaymentDoneDataSource] = useState([]);
  const [rejectCasesDataSource, setRejectCasesDataSource] = useState([]);
  const [otherCasesDataSource, setOtherCasesDataSource] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   dispatch(fetchFilterDetails({ jwtBearer })).then(() => {
  //     setLoading(false) // Set loading state to false when the data is fetched
  //   })
  // }, [])

  // const filterData = useSelector(state => state)

  // console.log('Filter Dropdown Data - ', filterData)

  // useEffect(() => {
  //   dispatch(fetchBranchReport({ jwtBearer })).then(() => {
  //     setLoading(false) // Set loading state to false when the data is fetched
  //   })
  // }, [])

  // const state = useSelector(state => state)

  useEffect(() => {
    // Dispatch both fetch actions concurrently
    Promise.all([
      dispatch(fetchBranchReport({ jwtBearer })),
      dispatch(fetchFilterDetails({ jwtBearer })), // Dispatch fetchFilterDetails as well
    ]).then(() => {
      setLoading(false); // Set loading state to false when both fetch actions are completed
    });
  }, []);

  const state = useSelector((state) => state);

  console.log("Branch Pipeline State - ", state);
  // console.log('Branch Pipeline filter - ', state.filter.data && state)
  console.log("Branch Pipeline data - ", state.branchReports.data && state);
  console.log();

  const tokenExpired = state.branchReports.data?.detail || "";
  const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition", tokenExpired);
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  const allFields =
    (state.branchReports.data && state.branchReports.data.message) || [];

  console.log("Branch All fields", allFields);

  const productFilter =
    (state.filter.data && state.filter.data.product_list) || [];

  const productOptions = productFilter.map((product) => (
    <Option key={product} value={product}>
      {product}
    </Option>
  ));

  const branchFilter =
    (state.filter.data && state.filter.data.branch_data) || [];

  console.log("Filter Data Response - ", branchFilter);

  const branchZoneFilter = branchFilter.zone;
  const branchStateFilter = branchFilter.state;
  const branchSupervisorFilter = branchFilter.supervisor_name;
  const branchDetailsFilter = branchFilter.branch_details;

  // branchDetailsFilter.sort((a, b) => {
  //   const codeA = parseInt(a.split(' - ')[0])
  //   const codeB = parseInt(b.split(' - ')[0])
  //   return codeA - codeB
  // })

  console.log("Branch data filter only", branchDetailsFilter);

  console.log("Branch Zone filter - ", branchZoneFilter);

  console.log("BRanch State filter - ", branchStateFilter);
  console.log("Branch Supervisor Filter", branchSupervisorFilter);

  console.log("Product Filter - ", productFilter);
  console.log("Branch Filter - ", branchFilter);

  console.log("All Fields Branch Pipeline - ", allFields);
  // console.log('Updated On Branch Pipeline - ', typeof allFields.UPDATED_ON)
  // console.log('Updated On Branch Pipeline - ', allFields.UPDATED_ON[0])
  // if (allFields.UPDATED_ON && allFields.UPDATED_ON.length > 0) {
  const updatedOn =
    (state.branchReports.data && state.branchReports.data.updated_on) || "";
  // useEffect(() => {
  dispatch(setUpdatedOn(updatedOn));
  // }, [])
  // onUpdatedOnChange(updatedOn)
  console.log("Const Updated On - ", updatedOn);
  // console.log('First Updated On - ', allFields.UPDATED_ON[0])
  // } else {
  //   console.log('No updated dates found.')
  // }

  useEffect(() => {
    const fieldArrays = Object.values(allFields);
    console.log("Fields Array Branch", fieldArrays);

    if (fieldArrays.length > 0) {
      const numRows = fieldArrays[0].length;
      const formattedData = [];
      const formattedSupervisorData = [];
      const formattedCreditData = [];
      const formattedVendorData = [];
      const formattedRejectData = [];
      const formattedOperationsData = [];
      const formattedPaymentPendingData = [];
      const formattedPaymentDoneData = [];
      const formattedOtherCasesData = [];

      for (let i = 0; i < numRows; i++) {
        const data = {};
        for (let j = 0; j < fieldArrays.length; j++) {
          const fieldName = Object.keys(allFields)[j];
          if (fieldName == "LEGAL_QUERY") {
            try {
              if (fieldArrays[j][i] != "NULL") {
                console.log(
                  "Latest Feedback In legal query condition",
                  JSON.parse(fieldArrays[j][i])
                );
                const FeedbackData = JSON.parse(fieldArrays[j][i]);
                if (FeedbackData && Array.isArray(FeedbackData)) {
                  FeedbackData.sort(
                    (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                  );
                  const latestFeedback = FeedbackData[0].Feedback;
                  console.log("Latest Feedback - ", latestFeedback);
                  // data[fieldName] = latestFeedback
                  const feedbackContent = showMore
                    ? latestFeedback
                    : `${latestFeedback} `;
                  const showMoreLink = (
                    <a
                      href="#"
                      onClick={() => handleShowMoreClick(FeedbackData)}
                    >
                      show more
                    </a>
                  );
                  // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                  data[fieldName] = (
                    <>
                      {feedbackContent}
                      {showMoreLink}
                    </>
                  );
                } else {
                  console.log(
                    "Invalid data format. Expected a non-null array."
                  );
                }
              }
            } catch (e) {
              // console.log("Exception in code - ", e, fieldArrays["APP_ID"][i]);
              // console.log("Exception in code - ", e, fieldArrays["APP_ID"][i] !== null ? fieldArrays["APP_ID"][i] : "value is null");
              // console.log("Exception in code - ", e);
              console.log(
                "Exception in code - ",
                e,
                fieldArrays["APP_ID"] && fieldArrays["APP_ID"][i] !== undefined
                  ? fieldArrays["APP_ID"][i]
                  : "value is null or undefined"
              );
            }
          } else if (fieldName == "TECHNICAL_QUERY") {
            if (fieldArrays[j][i] != "NULL") {
              console.log("Error check - ", fieldArrays[j][i]);
              console.log("Error check APP_ID- ", data["APP_ID"]);
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldArrays[j][i])
              );
              const FeedbackData = JSON.parse(fieldArrays[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
            // console.log('In technical query condition')
          } else {
            data[fieldName] = fieldArrays[j][i];
          }
        }

        if (data["STAGE"] === 1) {
          formattedData.push(data);
        } else if (data["STAGE"] === 2) {
          formattedSupervisorData.push(data);
        } else if (data["STAGE"] === 3) {
          formattedCreditData.push(data);
        } else if (data["STAGE"] === 4) {
          formattedVendorData.push(data);
        } else if (data["STAGE"] === 5) {
          formattedOperationsData.push(data);
        } else if (data["STAGE"] === 6) {
          formattedPaymentPendingData.push(data);
        } else if (data["STAGE"] === 7) {
          formattedPaymentDoneData.push(data);
        } else if (data["STAGE"] === 8) {
          formattedRejectData.push(data);
        } else if (data["STAGE"] === 9) {
          formattedOtherCasesData.push(data);
        }
      }

      setDataSource(formattedData);
      setSupervisorDataSource(formattedSupervisorData);
      setCreditDataSource(formattedCreditData);
      setVendorDataSource(formattedVendorData);
      setOperationsDataSource(formattedOperationsData);
      setPaymentPendingDataSource(formattedPaymentPendingData);
      setPaymentDoneDataSource(formattedPaymentDoneData);
      setRejectCasesDataSource(formattedRejectData);
      setOtherCasesDataSource(formattedOtherCasesData);
    }
  }, [allFields]);
  console.log("1st Datasource Old Logic - ", dataSource);

  // console.log('State Branch', state.reports.data)

  const paymentPendingallFields =
    (state.reports?.data &&
      state.reports.data.branchpipeline.paymentpendingcases.data) ||
    [];

  useEffect(() => {
    const fieldArrays = Object.values(paymentPendingallFields);

    if (fieldArrays.length > 0) {
      const numRows = fieldArrays[0].length;
      const formattedPaymentPendingData = [];

      for (let i = 0; i < numRows; i++) {
        const data = {};
        for (let j = 0; j < fieldArrays.length; j++) {
          const fieldName = Object.keys(paymentPendingallFields)[j];
          data[fieldName] = fieldArrays[j][i];
        }
        formattedPaymentPendingData.push(data);
      }

      setPaymentPendingDataSource(formattedPaymentPendingData);
    }
  }, [paymentPendingallFields]);

  const paymentDoneallFields =
    (state.reports?.data &&
      state.reports.data.branchpipeline.paymentdonecases.data) ||
    [];

  useEffect(() => {
    const fieldArrays = Object.values(paymentDoneallFields);

    if (fieldArrays.length > 0) {
      const numRows = fieldArrays[0].length;
      const formattedPaymentDoneData = [];

      for (let i = 0; i < numRows; i++) {
        const data = {};
        for (let j = 0; j < fieldArrays.length; j++) {
          const fieldName = Object.keys(paymentDoneallFields)[j];
          data[fieldName] = fieldArrays[j][i];
        }
        formattedPaymentDoneData.push(data);
      }

      setPaymentDoneDataSource(formattedPaymentDoneData);
    }
  }, [paymentDoneallFields]);

  console.log("Payment Done Data - ", paymentDoneDataSource);

  const handleRowClick = (record) => {
    // setIsModalVisible(true)
  };

  // useEffect(() => {
  //   if (allFields) {
  //       const fieldArraysPaymentDone = Object.keys(allFields.BA).map(key => ({
  //         stage: allFields.stage[key],
  //     cases: allFields.value[key]
  //   }))
  //   setPaymentDoneDataSource(fieldArraysPaymentDone)
  //   }
  // }, [allFields])

  console.log("Credit Datasource - ", dataSource);

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const [selectedZone, setSelectedZone] = useState("all");
  const [selectedState, setSelectedState] = useState("all");
  const [selectedSupervisor, setSelectedSupervisor] = useState("all");
  const [selectedBranch, setSelectedBranch] = useState("all");
  const [selectedProduct, setSelectedProduct] = useState("all");
  const [selectedBranchDetails, setSelectedBranchDetails] = useState([]);

  // ... (existing code)

  const handleFilterReset = () => {
    // Reset filter value
    const uniqueValues = [...new Set(Object.values(branchStateFilter))];
    const uniqueValuesSupervisor = [
      ...new Set(Object.values(branchSupervisorFilter)),
    ];
    const uniqueValuesBranches = [
      ...new Set(Object.values(branchDetailsFilter)),
    ];
    setFilteredStates(uniqueValues);
    setFilteredSupervisors(uniqueValuesSupervisor);
    setFilteredBranch(uniqueValuesBranches);
    // Reset filter value

    // Set default filter value to all
    setSelectedZone("all");
    setSelectedState("all");
    setSelectedSupervisor("all");
    setSelectedBranch("all");
    // Set default filter value to all

    const fieldArrays = Object.values(allFields);
    if (fieldArrays.length > 0) {
      console.log("In All data condition inside", fieldArrays);
      const numRows = fieldArrays[0].length;
      const formattedData = [];
      const formattedSupervisorData = [];
      const formattedCreditData = [];
      const formattedVendorData = [];
      const formattedRejectData = [];
      const formattedOperationsData = [];
      const formattedPaymentPendingData = [];
      const formattedPaymentDoneData = [];
      const formattedOtherCasesData = [];

      for (let i = 0; i < numRows; i++) {
        const data = {};
        for (let j = 0; j < fieldArrays.length; j++) {
          const fieldName = Object.keys(allFields)[j];
          data[fieldName] = fieldArrays[j][i];
        }

        if (data["STAGE"] === 1) {
          formattedData.push(data);
        } else if (data["STAGE"] === 2) {
          formattedSupervisorData.push(data);
        } else if (data["STAGE"] === 3) {
          formattedCreditData.push(data);
        } else if (data["STAGE"] === 4) {
          formattedVendorData.push(data);
        } else if (data["STAGE"] === 5) {
          formattedOperationsData.push(data);
        } else if (data["STAGE"] === 6) {
          formattedPaymentPendingData.push(data);
        } else if (data["STAGE"] === 7) {
          formattedPaymentDoneData.push(data);
        } else if (data["STAGE"] === 8) {
          formattedRejectData.push(data);
        } else if (data["STAGE"] === 9) {
          formattedOtherCasesData.push(data);
        }
      }

      setDataSource(formattedData);
      setSupervisorDataSource(formattedSupervisorData);
      setCreditDataSource(formattedCreditData);
      setVendorDataSource(formattedVendorData);
      setOperationsDataSource(formattedOperationsData);
      setPaymentPendingDataSource(formattedPaymentPendingData);
      setPaymentDoneDataSource(formattedPaymentDoneData);
      setRejectCasesDataSource(formattedRejectData);
      setOtherCasesDataSource(formattedOtherCasesData);
    }
  };

  const handleFilterChange = () => {
    let filteredBranchDetails = [];
    let filteredBranchDetailsZone = [];
    let filteredBranchDetailsState = [];
    let filteredBranchDetailsSupervisor = [];
    let filteredBranchDetailsBranch = [];
    let filteredData;

    if (
      selectedZone == "all" &&
      selectedState == "all" &&
      selectedSupervisor == "all" &&
      selectedBranch == "all"
    ) {
      console.log("In All data condition - ", allFields);
      const fieldArrays = Object.values(allFields);
      if (fieldArrays.length > 0) {
        console.log("In All data condition inside", fieldArrays);
        const numRows = fieldArrays[0].length;
        const formattedData = [];
        const formattedSupervisorData = [];
        const formattedCreditData = [];
        const formattedVendorData = [];
        const formattedRejectData = [];
        const formattedOperationsData = [];
        const formattedPaymentPendingData = [];
        const formattedPaymentDoneData = [];
        const formattedOtherCasesData = [];

        for (let i = 0; i < numRows; i++) {
          const data = {};
          for (let j = 0; j < fieldArrays.length; j++) {
            const fieldName = Object.keys(allFields)[j];
            data[fieldName] = fieldArrays[j][i];
          }

          if (data["STAGE"] === 1) {
            formattedData.push(data);
          } else if (data["STAGE"] === 2) {
            formattedSupervisorData.push(data);
          } else if (data["STAGE"] === 3) {
            formattedCreditData.push(data);
          } else if (data["STAGE"] === 4) {
            formattedVendorData.push(data);
          } else if (data["STAGE"] === 5) {
            formattedOperationsData.push(data);
          } else if (data["STAGE"] === 6) {
            formattedPaymentPendingData.push(data);
          } else if (data["STAGE"] === 7) {
            formattedPaymentDoneData.push(data);
          } else if (data["STAGE"] === 8) {
            formattedRejectData.push(data);
          } else if (data["STAGE"] === 9) {
            formattedOtherCasesData.push(data);
          }
        }

        setDataSource(formattedData);
        setSupervisorDataSource(formattedSupervisorData);
        setCreditDataSource(formattedCreditData);
        setVendorDataSource(formattedVendorData);
        setOperationsDataSource(formattedOperationsData);
        setPaymentPendingDataSource(formattedPaymentPendingData);
        setPaymentDoneDataSource(formattedPaymentDoneData);
        setRejectCasesDataSource(formattedRejectData);
        setOtherCasesDataSource(formattedOtherCasesData);
      }
    } else {
      filteredBranchDetails = Object.entries(branchFilter.branch_details)
        .filter(([_, branch_details]) => {
          return (
            (selectedZone === "all" || branchFilter.zone[_] === selectedZone) &&
            (selectedState === "all" ||
              branchFilter.state[_] === selectedState) &&
            (selectedSupervisor === "all" ||
              branchFilter.supervisor_name[_] === selectedSupervisor) &&
            (selectedBranch === "all" || branch_details === selectedBranch)
          );
        })
        .map(([index]) => branchFilter.branch_details[index].split("-")[0]);

      filteredData = Object.keys(allFields).reduce((filtered, key) => {
        filtered[key] = allFields[key].filter((_, index) =>
          filteredBranchDetails.includes(allFields.BRANCH_ID[index])
        );
        return filtered;
      }, {});
      const fieldArrays = Object.values(filteredData);

      if (fieldArrays.length > 0) {
        const numRows = fieldArrays[0].length;
        const formattedData = [];
        const formattedSupervisorData = [];
        const formattedCreditData = [];
        const formattedVendorData = [];
        const formattedRejectData = [];
        const formattedOperationsData = [];
        const formattedPaymentPendingData = [];
        const formattedPaymentDoneData = [];
        const formattedOtherCasesData = [];

        for (let i = 0; i < numRows; i++) {
          const data = {};
          for (let j = 0; j < fieldArrays.length; j++) {
            const fieldName = Object.keys(allFields)[j];
            data[fieldName] = fieldArrays[j][i];
          }

          if (data["STAGE"] === 1) {
            formattedData.push(data);
          } else if (data["STAGE"] === 2) {
            formattedSupervisorData.push(data);
          } else if (data["STAGE"] === 3) {
            formattedCreditData.push(data);
          } else if (data["STAGE"] === 4) {
            formattedVendorData.push(data);
          } else if (data["STAGE"] === 5) {
            formattedOperationsData.push(data);
          } else if (data["STAGE"] === 6) {
            formattedPaymentPendingData.push(data);
          } else if (data["STAGE"] === 7) {
            formattedPaymentDoneData.push(data);
          } else if (data["STAGE"] === 8) {
            formattedRejectData.push(data);
          } else if (data["STAGE"] === 9) {
            formattedOtherCasesData.push(data);
          }
        }

        setDataSource(formattedData);
        setSupervisorDataSource(formattedSupervisorData);
        setCreditDataSource(formattedCreditData);
        setVendorDataSource(formattedVendorData);
        setOperationsDataSource(formattedOperationsData);
        setPaymentPendingDataSource(formattedPaymentPendingData);
        setPaymentDoneDataSource(formattedPaymentDoneData);
        setRejectCasesDataSource(formattedRejectData);
        setOtherCasesDataSource(formattedOtherCasesData);
      }
    }
  };

  // const handleZoneChange = value => {
  //   setSelectedZone(value)
  //   if (value != 'all') {
  //     console.log('In zone filter State condition')
  //     // const filteredStateonZone = Object.entries(branchFilter.zone)
  //     branchStateFilter = Object.entries(branchFilter.zone)
  //       .filter(([_, zone]) => zone === value)
  //       .map(([index]) => branchFilter.state[index].split('-')[0])
  //     console.log('Zone filtered States - ', branchStateFilter)
  //   }
  // }

  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredSupervisors, setFilteredSupervisors] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);

  console.log("If branchStateFilter is defined - ", branchStateFilter);

  const handleZoneChange = (value) => {
    setSelectedZone(value);
    if (selectedState != "all") {
      setSelectedState("all");
    }
    if (selectedSupervisor != "all") {
      setSelectedSupervisor("all");
    }
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    if (value === "all") {
      console.log("In zone all condition");
      const uniqueValues = [...new Set(Object.values(branchStateFilter))];
      const uniqueValuesSupervisor = [
        ...new Set(Object.values(branchSupervisorFilter)),
      ];
      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredStates(uniqueValues);
      setFilteredSupervisors(uniqueValuesSupervisor);
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const statesForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchStateFilter[index] : null
            )
            .filter((state) => state !== null)
        ),
      ];

      const supervisorsForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchSupervisorFilter[index] : null
            )
            .filter((supervisor_name) => supervisor_name !== null)
        ),
      ];

      const branchesForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredStates(statesForSelectedZone);
      setFilteredSupervisors(supervisorsForSelectedZone);
      setFilteredBranch(branchesForSelectedZone);
    }
  };

  const handleStateChange = (value) => {
    if (selectedSupervisor != "all") {
      setSelectedSupervisor("all");
    }
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    setSelectedState(value);

    if (value === "all") {
      console.log("In branch all condition");
      const uniqueValuesSupervisor = [
        ...new Set(Object.values(branchSupervisorFilter)),
      ];
      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredSupervisors(uniqueValuesSupervisor);
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const supervisorsForSelectedState = [
        ...new Set(
          Object.values(branchStateFilter)
            .map((state, index) =>
              state === value ? branchSupervisorFilter[index] : null
            )
            .filter((supervisor_name) => supervisor_name !== null)
        ),
      ];

      const branchesForSelectedState = [
        ...new Set(
          Object.values(branchStateFilter)
            .map((state, index) =>
              state === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredSupervisors(supervisorsForSelectedState);
      setFilteredBranch(branchesForSelectedState);
    }
  };

  const handleSupervisorChange = (value) => {
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    setSelectedSupervisor(value);

    if (value === "all") {
      console.log("In supervisor all condition");

      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const branchesForSelectedSupervisor = [
        ...new Set(
          Object.values(branchSupervisorFilter)
            .map((supervisor_name, index) =>
              supervisor_name === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredBranch(branchesForSelectedSupervisor);
    }
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value);
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
  };

  const filterDataSource = () => {
    let filteredData = dataSource;

    if (selectedStage !== "all") {
      filteredData = filteredData.filter(
        (item) => item.STAGE === Number(selectedStage)
      );
    }

    if (selectedProduct !== "all") {
      filteredData = filteredData.filter(
        (item) => item.PRODUCT === selectedProduct
      );
    }

    if (selectedStatus !== "all") {
      filteredData = filteredData.filter(
        (item) => item.CURR_STATUS === selectedStatus
      );
    }

    if (selectedMobile !== "") {
      filteredData = filteredData.filter((item) =>
        item.APPLICANT_MOBILE.includes(selectedMobile)
      );
    }

    return filteredData;
  };

  const uniqueBAPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchPendingWithBranch.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePendingWithBranch = [
    ...new Set(dataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePendingWithBranch = [
    ...new Set(dataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePendingWithBranch = [
    ...new Set(dataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypePendingWithBranch = [
    ...new Set(dataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PendingWithBranch = [
    ...new Set(dataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.UDYAM_AADHAR)),
  ];
  const uniqueCurrStatusPendingWithBranch = [
    ...new Set(dataSource.map((item) => item.CURR_STATUS)),
  ];

  const csvHeaders = [
    { label: "BA", key: "BA" },
    { label: "BRANCH", key: "BR_CD" },
    { label: "SUPERVISOR", key: "supervisor_manager" },
    { label: "LOAN TYPE", key: "TYPE" },
    { label: "APP ID", key: "APP_ID" },
    { label: "REPEAT APP ID", key: "REPEAT_APP_ID" },
    { label: "APPLICANT_NAME", key: "APPLICANT_NAME" },
    { label: "APPLICANT MOBILE", key: "APPLICANT_MOBILE" },
    { label: "PRODUCT", key: "PRODUCT" },
    { label: "BUSINESS TYPE", key: "BUSINESS_TYPE" },
    { label: "BUSINESS TYPE 1", key: "BUSINESS_TYPE_1" },
    { label: "PIPELINE STATUS", key: "PIPELINE_STATUS" },
    { label: "INS ENTITY", key: "INS_ENTITY" },
    { label: "UDYAM AADHAR", key: "UDYAM_AADHAR" },
    { label: "APPLIED LOAN AMT", key: "REQ_LOAN_AMT" },
    { label: "CURRENT ACTIVITY", key: "CURR_STATUS" },
    { label: "LEGAL QUERY", key: "LEGAL_QUERY" },
    { label: "TECHNICAL QUERY", key: "TECHNICAL_QUERY" },
    { label: "APP TECHNICAL STATUS", key: "APP_TECHNICAL_STATUS" },
    { label: "APP LEGAL STATUS", key: "APP_LEGAL_STATUS" },
    {
      label: "BUSINESS VERIFICATION COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
    },
    {
      label: "CREDIT DECISIONING COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
    },
    { label: "CURR STATUS UPDATED", key: "CURR_STATUS_UPDATED" },
  ];

  const columnsPendingWithBranch = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPendingWithBranch.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPendingWithBranch.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPendingWithBranch.map((supervisor_manager) => ({
        text: supervisor_manager,
        value: supervisor_manager,
      })),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePendingWithBranch.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPendingWithBranch.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPendingWithBranch.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePendingWithBranch.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobilePendingWithBranch.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPendingWithBranch.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePendingWithBranch.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PendingWithBranch.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPendingWithBranch.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPendingWithBranch.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPendingWithBranch.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusPendingWithBranch.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.BR_CD)),
  ];
  const uniqueSupervisorPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.TYPE)),
  ];
  uniqueBranchPendingWithSupervisor.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueAppIDPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypePendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.UDYAM_AADHAR)),
  ];
  const uniqueCurrStatusPendingWithSupervisor = [
    ...new Set(supervisorDataSource.map((item) => item.CURR_STATUS)),
  ];

  const columnsPendingWithSupervisor = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPendingWithSupervisor.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPendingWithSupervisor.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPendingWithSupervisor.map(
        (supervisor_manager) => ({
          text: supervisor_manager,
          value: supervisor_manager,
        })
      ),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePendingWithSupervisor.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPendingWithSupervisor.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPendingWithSupervisor.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePendingWithSupervisor.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobilePendingWithSupervisor.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPendingWithSupervisor.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePendingWithSupervisor.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PendingWithSupervisor.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPendingWithSupervisor.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPendingWithSupervisor.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPendingWithSupervisor.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusPendingWithSupervisor.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchPendingWithCentralCredit.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypePendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.UDYAM_AADHAR)),
  ];
  const uniqueCurrStatusPendingWithCentralCredit = [
    ...new Set(creditDataSource.map((item) => item.CURR_STATUS)),
  ];

  const columnsPendingWithCentralCredit = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPendingWithCentralCredit.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPendingWithCentralCredit.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPendingWithCentralCredit.map(
        (supervisor_manager) => ({
          text: supervisor_manager,
          value: supervisor_manager,
        })
      ),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePendingWithCentralCredit.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",

      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPendingWithCentralCredit.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPendingWithCentralCredit.map(
        (REPEAT_APP_ID) => ({
          text: REPEAT_APP_ID,
          value: REPEAT_APP_ID,
        })
      ),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePendingWithCentralCredit.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobilePendingWithCentralCredit.map(
        (APPLICANT_MOBILE) => ({
          text: APPLICANT_MOBILE,
          value: APPLICANT_MOBILE,
        })
      ),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPendingWithCentralCredit.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePendingWithCentralCredit.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PendingWithCentralCredit.map(
        (BUSINESS_TYPE_1) => ({
          text: BUSINESS_TYPE_1,
          value: BUSINESS_TYPE_1,
        })
      ),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPendingWithCentralCredit.map(
        (PIPELINE_STATUS) => ({
          text: PIPELINE_STATUS,
          value: PIPELINE_STATUS,
        })
      ),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPendingWithCentralCredit.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPendingWithCentralCredit.map(
        (UDYAM_AADHAR) => ({
          text: UDYAM_AADHAR,
          value: UDYAM_AADHAR,
        })
      ),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusPendingWithCentralCredit.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchPendingWithExternalVendor.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypePendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.UDYAM_AADHAR)),
  ];
  const uniqueCurrStatusPendingWithExternalVendor = [
    ...new Set(vendorDataSource.map((item) => item.CURR_STATUS)),
  ];

  const columnsPendingWithExternalVendor = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPendingWithExternalVendor.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPendingWithExternalVendor.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPendingWithExternalVendor.map(
        (supervisor_manager) => ({
          text: supervisor_manager,
          value: supervisor_manager,
        })
      ),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePendingWithExternalVendor.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",

      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPendingWithExternalVendor.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPendingWithExternalVendor.map(
        (REPEAT_APP_ID) => ({
          text: REPEAT_APP_ID,
          value: REPEAT_APP_ID,
        })
      ),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePendingWithExternalVendor.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobilePendingWithExternalVendor.map(
        (APPLICANT_MOBILE) => ({
          text: APPLICANT_MOBILE,
          value: APPLICANT_MOBILE,
        })
      ),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPendingWithExternalVendor.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePendingWithExternalVendor.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PendingWithExternalVendor.map(
        (BUSINESS_TYPE_1) => ({
          text: BUSINESS_TYPE_1,
          value: BUSINESS_TYPE_1,
        })
      ),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPendingWithExternalVendor.map(
        (PIPELINE_STATUS) => ({
          text: PIPELINE_STATUS,
          value: PIPELINE_STATUS,
        })
      ),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPendingWithExternalVendor.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPendingWithExternalVendor.map(
        (UDYAM_AADHAR) => ({
          text: UDYAM_AADHAR,
          value: UDYAM_AADHAR,
        })
      ),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusPendingWithExternalVendor.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchPendingWithOperations.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypePendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.UDYAM_AADHAR)),
  ];
  const uniqueCurrStatusPendingWithOperations = [
    ...new Set(operationsDataSource.map((item) => item.CURR_STATUS)),
  ];

  const columnsPendingWithOperations = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPendingWithOperations.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPendingWithOperations.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPendingWithOperations.map(
        (supervisor_manager) => ({
          text: supervisor_manager,
          value: supervisor_manager,
        })
      ),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePendingWithOperations.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",

      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPendingWithOperations.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",

      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPendingWithOperations.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePendingWithOperations.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobilePendingWithOperations.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPendingWithOperations.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePendingWithOperations.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PendingWithOperations.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPendingWithOperations.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPendingWithOperations.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPendingWithOperations.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusPendingWithOperations.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBARejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchRejectCases.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypeRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNameRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobileRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueCurrStatusRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.CURR_STATUS)),
  ];

  const uniqueBusTypeRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1RejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharRejectCases = [
    ...new Set(rejectCasesDataSource.map((item) => item.UDYAM_AADHAR)),
  ];

  const columnsRejectCases = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBARejectCases.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchRejectCases.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorRejectCases.map((supervisor_manager) => ({
        text: supervisor_manager,
        value: supervisor_manager,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypeRejectCases.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDRejectCases.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDRejectCases.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNameRejectCases.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobileRejectCases.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductRejectCases.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypeRejectCases.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1RejectCases.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusRejectCases.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityRejectCases.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharRejectCases.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusRejectCases.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchOtherCases.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypeOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNameOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobileOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueCurrStatusOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.CURR_STATUS)),
  ];

  const uniqueBusTypeOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1OtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharOtherCases = [
    ...new Set(otherCasesDataSource.map((item) => item.UDYAM_AADHAR)),
  ];

  const columnsOtherCases = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAOtherCases.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchOtherCases.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorOtherCases.map((supervisor_manager) => ({
        text: supervisor_manager,
        value: supervisor_manager,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypeOtherCases.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDOtherCases.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDOtherCases.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNameOtherCases.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobileOtherCases.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductOtherCases.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypeOtherCases.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1OtherCases.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusOtherCases.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityOtherCases.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharOtherCases.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueCurrStatusOtherCases.map((CURR_STATUS) => ({
        text: CURR_STATUS,
        value: CURR_STATUS,
      })),
      onFilter: (value, record) => record.CURR_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchPaymentPending.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.PRODUCT)),
  ];
  const uniqueBusTypePaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPaymentPending = [
    ...new Set(paymentPendingDataSource.map((item) => item.UDYAM_AADHAR)),
  ];

  const columns2 = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPaymentPending.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPaymentPending.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPaymentPending.map((supervisor_manager) => ({
        text: supervisor_manager,
        value: supervisor_manager,
      })),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePaymentPending.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPaymentPending.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPaymentPending.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePaymentPending.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile</strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      filters: uniqueAppMobilePaymentPending.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPaymentPending.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePaymentPending.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PaymentPending.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPaymentPending.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPaymentPending.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPaymentPending.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      ...getColumnSearchProps("REQ_LOAN_AMT"),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const uniqueBAPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.BA)),
  ];
  const uniqueBranchPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.BR_CD)),
  ];
  uniqueBranchPaymentDone.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueSupervisorPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.supervisor_manager)),
  ];
  const uniqueTypePaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.TYPE)),
  ];
  const uniqueAppIDPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.APP_ID)),
  ];
  const uniqueRepeatAppIDPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueAppNamePaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const uniqueAppMobilePaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.APPLICANT_MOBILE)),
  ];
  const uniqueProductPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.PRODUCT)),
  ];

  const uniqueBusTypePaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.BUSINESS_TYPE)),
  ];
  const uniqueBusType1PaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.BUSINESS_TYPE_1)),
  ];
  const uniquePipeStatusPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.PIPELINE_STATUS)),
  ];
  const uniqueINSEntityPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.INS_ENTITY)),
  ];
  const uniqueUdyamAadharPaymentDone = [
    ...new Set(paymentDoneDataSource.map((item) => item.UDYAM_AADHAR)),
  ];

  const columns3 = [
    {
      title: <strong style={{ fontSize: "18px" }}>BA</strong>,
      dataIndex: "BA",
      key: "BA",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBAPaymentDone.map((BA) => ({
        text: BA,
        value: BA,
      })),
      onFilter: (value, record) => record.BA === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranchPaymentDone.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "supervisor_manager",
      key: "supervisor_manager",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisorPaymentDone.map((supervisor_manager) => ({
        text: supervisor_manager,
        value: supervisor_manager,
      })),
      onFilter: (value, record) => record.supervisor_manager === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueTypePaymentDone.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID </strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppIDPaymentDone.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID </strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueRepeatAppIDPaymentDone.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppNamePaymentDone.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Mobile </strong>,
      dataIndex: "APPLICANT_MOBILE",
      key: "APPLICANT_MOBILE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppMobilePaymentDone.map((APPLICANT_MOBILE) => ({
        text: APPLICANT_MOBILE,
        value: APPLICANT_MOBILE,
      })),
      onFilter: (value, record) => record.APPLICANT_MOBILE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Product</strong>,
      dataIndex: "PRODUCT",
      key: "PRODUCT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueProductPaymentDone.map((PRODUCT) => ({
        text: PRODUCT,
        value: PRODUCT,
      })),
      onFilter: (value, record) => record.PRODUCT === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type</strong>,
      dataIndex: "BUSINESS_TYPE",
      key: "BUSINESS_TYPE",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusTypePaymentDone.map((BUSINESS_TYPE) => ({
        text: BUSINESS_TYPE,
        value: BUSINESS_TYPE,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Business Type 1</strong>,
      dataIndex: "BUSINESS_TYPE_1",
      key: "BUSINESS_TYPE_1",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBusType1PaymentDone.map((BUSINESS_TYPE_1) => ({
        text: BUSINESS_TYPE_1,
        value: BUSINESS_TYPE_1,
      })),
      onFilter: (value, record) => record.BUSINESS_TYPE_1 === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pipeline Status</strong>,
      dataIndex: "PIPELINE_STATUS",
      key: "PIPELINE_STATUS",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniquePipeStatusPaymentDone.map((PIPELINE_STATUS) => ({
        text: PIPELINE_STATUS,
        value: PIPELINE_STATUS,
      })),
      onFilter: (value, record) => record.PIPELINE_STATUS === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>INS Entity</strong>,
      dataIndex: "INS_ENTITY",
      key: "INS_ENTITY",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueINSEntityPaymentDone.map((INS_ENTITY) => ({
        text: INS_ENTITY,
        value: INS_ENTITY,
      })),
      onFilter: (value, record) => record.INS_ENTITY === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Udyam Aadhar</strong>,
      dataIndex: "UDYAM_AADHAR",
      key: "UDYAM_AADHAR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueUdyamAadharPaymentDone.map((UDYAM_AADHAR) => ({
        text: UDYAM_AADHAR,
        value: UDYAM_AADHAR,
      })),
      onFilter: (value, record) => record.UDYAM_AADHAR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },

    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUSTECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "14px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];

  const { Panel } = Collapse;

  const handleShowMoreClick = (e) => {
    setShowMoreModalVisible(true);
    setShowMoreData(e);
    console.log("Show more on click", e);
  };

  const handleCancel = () => {
    setShowMoreModalVisible(false);
  };

  return (
    <>
      {/* {jwt_payload_group.includes('1') ||
      jwt_payload_group.includes('3') ? null : ( */}
      {jwt_payload_group == "1" || jwt_payload_group == "3" ? null : (
        // <div></div>

        <div className="filters" style={{ marginBottom: 20, display: 'flex', flexWrap: 'wrap', gap: 15, alignItems: 'center' }}>
          <div style={{ flex: '1 1 200px' }}>
            <label>
              <strong>Zone: </strong>
            </label>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Zone"
              value={selectedZone}
              onChange={handleZoneChange}
            >
              <Option value="all">All</Option>
              {branchZoneFilter &&
                Object.values(branchZoneFilter)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((zone, index) => (
                    <Option key={index} value={zone}>
                      {zone}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: '1 1 200px' }}>
            <label>
              <strong>State: </strong>
            </label>
            <Select
              style={{ width: '100%' }}
              placeholder="Select State"
              value={selectedState}
              onChange={handleStateChange}
            >
              <Option value="all">All</Option>
              {filteredStates.length !== 0
                ? filteredStates.map((state, index) => (
                  <Option key={index} value={state}>
                    {state}
                  </Option>
                ))
                : branchStateFilter &&
                Object.values(branchStateFilter)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: '1 1 200px' }}>
            <label>
              <strong>Supervisor: </strong>
            </label>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Supervisor"
              value={selectedSupervisor}
              onChange={handleSupervisorChange}
            >
              <Option value="all">All</Option>
              {filteredSupervisors.length !== 0
                ? filteredSupervisors.map((supervisor_name, index) => (
                  <Option key={index} value={supervisor_name}>
                    {supervisor_name}
                  </Option>
                ))
                : branchSupervisorFilter &&
                Object.values(branchSupervisorFilter)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((supervisor_name, index) => (
                    <Option key={index} value={supervisor_name}>
                      {supervisor_name}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: '1 1 200px' }}>
            <label>
              <strong>Branch: </strong>
            </label>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Branch"
              value={selectedBranch}
              onChange={handleBranchChange}
            >
              <Option value="all">All</Option>
              {filteredBranch.length !== 0
                ? filteredBranch.map((branch_details, index) => (
                  <Option key={index} value={branch_details}>
                    {branch_details}
                  </Option>
                ))
                : branchDetailsFilter &&
                Object.values(branchDetailsFilter)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((branch_details, index) => (
                    <Option key={index} value={branch_details}>
                      {branch_details}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ display: 'flex', gap: 50, marginTop: 10 }}>
            <MyBtn

              onClick={handleFilterChange}

              style={{
                width: 100,
                height: 40,
                margin: "auto",


              }}
            >
              Apply Filter
            </MyBtn>
            <button


              onClick={handleFilterReset}
              size="medium"
              style={{
                borderRadius: "20px",
                background: "white",
                color: "black",
                fontWeight: "600",
                padding: "0 1.2rem",
                color: "blue",
                border: "solid blue 2px",
                fontSize: "16px",
                cursor: "pointer"
              }}
            >
              Reset
            </button>
          </div>
        </div>

      )}
      <div
        style={{
          height: "100vh",
          overflowY: "none",
          width: "100%",
          padding: "0px"
          // background: 'white'
        }}
      >
        {/* <Collapse accordion> */}
        {/* {dataSource.length > 0 && ( */}
        {/* <Panel header='CASES PENDING WITH BRANCHES' key='1'> */}
        <Card
          layout="horizontal"
          bordered={true}
          className="criclebox tablespace mb-24"
          title="CASES PENDING WITH BRANCHES"
          centered
          style={{ width: "100%" }}
          extra={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",

              }}
            >
              {
                // jwt_payload_group == '2' || jwt_payload_group == '3' ? null : (
                <StyledCSVLink

                  data={dataSource}
                  headers={csvHeaders}
                  onClick={() => { }}
                >
                  Download Report
                </StyledCSVLink>
                // )
              }
            </div>
          }
        >
          <div className="table-responsive">
            {loading ? ( // Conditionally render the loading spinner or the table
              <div className="spinner-container">

                <HashLoader color="#f57171" />
              </div>
            ) : (
              <StyledTable
                onRow={(record, rowIndex) => ({
                  onClick: () => handleRowClick(record),
                })}
                columns={columnsPendingWithBranch}
                dataSource={dataSource}
                // pagination={false}
                pagination={paginationOptions}
                bordered

              />
            )}
          </div>
        </Card>
        {/* </Panel> */}
        {/* )} */}
        {supervisorDataSource.length > 0 && (
          // <Panel header='CASES PENDING WITH SUPERVIOSR' key='2'>
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="CASES PENDING WITH SUPERVISOR"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={supervisorDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columnsPendingWithSupervisor}
                  dataSource={supervisorDataSource}
                  pagination={paginationOptions}
                // pagination={false}

                />
              )}
            </div>
          </Card>
          // </Panel>
        )}
        {creditDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="CASES PENDING WITH CENTRAL CREDIT"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={creditDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columnsPendingWithCentralCredit}
                  dataSource={creditDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {vendorDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="CASES PENDING WITH EXTERNAL VENDOR"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={vendorDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columnsPendingWithExternalVendor}
                  dataSource={vendorDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {operationsDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="CASES PENDING WITH OPERATIONS"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={operationsDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columnsPendingWithOperations}
                  dataSource={operationsDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {paymentPendingDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="PAYMENT PENDING CASES"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={paymentPendingDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columns2}
                  dataSource={paymentPendingDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {paymentDoneDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="PAYMENT DONE CASES"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={paymentDoneDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columns3}
                  dataSource={paymentDoneDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {rejectCasesDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="REJECT CASES"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={rejectCasesDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columnsRejectCases}
                  dataSource={rejectCasesDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {otherCasesDataSource.length > 0 && (
          <Card
            layout="horizontal"
            bordered={true}
            className="criclebox tablespace mb-24"
            title="OTHER CASES"
            centered
            style={{ width: "100%" }}
            extra={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                {
                  // jwt_payload_group == '2' ||
                  // jwt_payload_group == '3' ? null : (
                  <StyledCSVLink

                    data={otherCasesDataSource}
                    headers={csvHeaders}
                    onClick={() => { }}
                  >
                    Download Report
                  </StyledCSVLink>
                  // )
                }
              </div>
            }
          >
            <div className="table-responsive">
              {loading ? ( // Conditionally render the loading spinner or the table
                <div className="spinner-container">
                  <HashLoader color="#f57171" />
                </div>
              ) : (
                <StyledTable
                  onRow={(record, rowIndex) => ({
                    onClick: () => handleRowClick(record),
                  })}
                  columns={columnsOtherCases}
                  dataSource={otherCasesDataSource}
                  pagination={paginationOptions}

                />
              )}
            </div>
          </Card>
        )}
        {/* </Collapse> */}
        <Modal
          visible={showMoreModalVisible}
          onCancel={handleCancel}
          width={1300}
          footer={null}
        >
          <div
            style={{
              maxHeight: "850px", // Adjust the max height as needed
              overflowY: "auto",
            }}
          >
            {showMoreData?.length > 0 && (
              <p>
                <strong>QUERY TRAIL</strong>
                <br />
                <br />
              </p>
            )}
            {showMoreData?.length > 0 &&
              showMoreData.map((trailItem, index) => (
                <div key={index}>
                  <Tag color="blue">{trailItem.DateTime}</Tag> <br /> <br />
                  {/* <ArrowRightOutlined />
              <Tag color='blue' style={{marginLeft: 8}}>{trailItem.Feedback}</Tag>
              <Tag color='green' style={{marginLeft: 8}}>{trailItem.Feedback}</Tag> */}
                  <strong>Stage:</strong>{" "}
                  <div
                    style={{
                      maxHeight: "90px", // Adjust the max height as needed
                      maxWidth: "800px",
                      marginTop: "10px",
                      overflowY: "auto",
                      border: "1px solid #e8e8e8",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {trailItem.Stage}
                  </div>{" "}
                  <br />
                  <strong>Feedback: </strong>
                  <div
                    style={{
                      maxHeight: "90px", // Adjust the max height as needed
                      maxWidth: "800px",
                      marginTop: "10px",
                      overflowY: "auto",
                      border: "1px solid #e8e8e8",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {trailItem.Feedback}
                  </div>
                  <Divider />
                </div>
              ))}
          </div>
        </Modal>
        {/* <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            width="1500"
            height="1000"
            src="https://analytics.zoho.in/open-view/249291000002699965/f9043c3cb092e1324919e4659be2929a">
          </iframe> */}
        {/* <iframe width="1500" height="1000" 
          src="https://analytics.zoho.in/open-view/249291000002699965/f9043c3cb092e1324919e4659be2929a?ZOHO_CRITERIA=%22branch_info_master%22.%22branch_id%22=10001"
          ></iframe> */}
      </div>
    </>
  );
}

export default PipelineTable;
