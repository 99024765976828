import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupervisorReport } from "../../../redux/slice/supervisorReportSlice";
import { useAuth } from "../../../context/AuthContext";
import jwt_decode from "jwt-decode";
// import { CSVLink } from "react-csv";
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Select,
  Tag,
  Divider,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { setSupervisorUpdatedOn } from "../../../redux/slice/updatedOnSupervisorSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchFilterDetails } from "../../../redux/slice/filterSlice";
import "../../../../assets/styles/buttons.css";
import { HashLoader } from "react-spinners";
import {
  StyledModal,
  StyledCard,
  StyledRow,
  StyledCol,
  StyledTable,
  MyBtn,
  StyledCSVLink,
} from "../components/styleComp";

const { Search } = Input;

function CreditPipeline(props) {
  const [dataSource, setDataSource] = useState([]);
  const [stageDataSource, setStageDataSource] = useState([]);
  const [casesTotal, setCasesTotal] = useState();
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [detailedLoading, setDetailedLoading] = useState(true);
  const [selectedStage, setSelectedStage] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [showMoreModalVisible, setShowMoreModalVisible] = useState(false);
  const [showMoreData, setShowMoreData] = useState({});

  const dispatch = useDispatch();

  const uniquePendingFor = [
    ...new Set(dataSource.map((item) => item.PENDING_FOR)),
  ];
  const uniqueBranch = [...new Set(dataSource.map((item) => item.BR_CD))];
  uniqueBranch.sort((a, b) => {
    const codeA = parseInt(a.split(" - ")[0]);
    const codeB = parseInt(b.split(" - ")[0]);
    return codeA - codeB;
  });
  const uniqueType = [...new Set(dataSource.map((item) => item.TYPE))];
  const uniqueAppID = [...new Set(dataSource.map((item) => item.APP_ID))];
  const uniqueRepeatAppID = [
    ...new Set(dataSource.map((item) => item.REPEAT_APP_ID)),
  ];
  const uniqueSupervisor = [
    ...new Set(dataSource.map((item) => item.SUPERVISOR)),
  ];
  const uniqueAppName = [
    ...new Set(dataSource.map((item) => item.APPLICANT_NAME)),
  ];
  const columns = [
    {
      title: <strong style={{ fontSize: "18px" }}>Pending For</strong>,
      dataIndex: "PENDING_FOR",
      key: "PENDING_FOR",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniquePendingFor.map((PENDING_FOR) => ({
        text: PENDING_FOR,
        value: PENDING_FOR,
      })),
      onFilter: (value, record) => record.PENDING_FOR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Branch</strong>,
      dataIndex: "BR_CD",
      key: "BR_CD",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueBranch.map((BR_CD) => ({
        text: BR_CD,
        value: BR_CD,
      })),
      onFilter: (value, record) => record.BR_CD === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Supervisor</strong>,
      dataIndex: "SUPERVISOR",
      key: "SUPERVISOR",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueSupervisor.map((SUPERVISOR) => ({
        text: SUPERVISOR,
        value: SUPERVISOR,
      })),
      onFilter: (value, record) => record.SUPERVISOR === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Loan Type</strong>,
      dataIndex: "TYPE",
      key: "TYPE",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueType.map((TYPE) => ({
        text: TYPE,
        value: TYPE,
      })),
      onFilter: (value, record) => record.TYPE === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Application ID</strong>,
      dataIndex: "APP_ID",
      key: "APP_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniqueAppID.map((APP_ID) => ({
        text: APP_ID,
        value: APP_ID,
      })),
      onFilter: (value, record) => record.APP_ID === value,
      filterSearch: true,
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Repeat Application ID</strong>
      ),
      dataIndex: "REPEAT_APP_ID",
      key: "REPEAT_APP_ID",
      width: "70%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      className: "table-heading",
      filters: uniqueRepeatAppID.map((REPEAT_APP_ID) => ({
        text: REPEAT_APP_ID,
        value: REPEAT_APP_ID,
      })),
      onFilter: (value, record) => record.REPEAT_APP_ID === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applicant Name</strong>,
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
      filters: uniqueAppName.map((APPLICANT_NAME) => ({
        text: APPLICANT_NAME,
        value: APPLICANT_NAME,
      })),
      onFilter: (value, record) => record.APPLICANT_NAME === value,
      filterSearch: true,
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Applied Loan Amount</strong>,
      dataIndex: "REQ_LOAN_AMT",
      key: "REQ_LOAN_AMT",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Current Activity</strong>,
      dataIndex: "CURR_STATUS",
      key: "CURR_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Pending Since</strong>,
      dataIndex: "CURR_STATUS_UPDATED",
      key: "CURR_STATUS_UPDATED",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Legal Query</strong>,
      dataIndex: "LEGAL_QUERY",
      key: "LEGAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: <strong style={{ fontSize: "18px" }}>Technical Query</strong>,
      dataIndex: "TECHNICAL_QUERY",
      key: "TECHNICAL_QUERY",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Application Technical Status
        </strong>
      ),
      dataIndex: "APP_TECHNICAL_STATUS",
      key: "APP_TECHNICAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>Application Legal Status</strong>
      ),
      dataIndex: "APP_LEGAL_STATUS",
      key: "APP_LEGAL_STATUS",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Business Verification Completion
        </strong>
      ),
      dataIndex: "BUSINESS_VERIFICATION_COMPLETION",
      key: "BUSINESS_VERIFICATION_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
    {
      title: (
        <strong style={{ fontSize: "18px" }}>
          Credit Decisioning Completion
        </strong>
      ),
      dataIndex: "CREDIT_DECISIONING_COMPLETION",
      key: "CREDIT_DECISIONING_COMPLETION",
      width: "30%",
      align: "left",
      render: (text) => (
        // <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
        <span className="poppins-font" style={{ fontSize: "16px" }}>
          {text}
        </span>
      ),
    },
  ];
  const { authState, onLogout } = useAuth();
  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails.group_id;
    console.log("JWT Group Reports - ", jwt_payload_group);
  }

  console.log("JWT Bearer Supervisor - ", jwtBearer);

  useEffect(() => {
    // Dispatch both fetch actions concurrently
    Promise.all([
      dispatch(fetchSupervisorReport({ jwtBearer })),
      dispatch(fetchFilterDetails()), // Dispatch fetchFilterDetails as well
    ]).then(() => {
      setLoading(false); // Set loading state to false when both fetch actions are completed
    });
  }, []);

  // useEffect(() => {
  //   dispatch(fetchSupervisorReport({ jwtBearer })).then(() => {
  //     setLoading(false) // Set loading state to false when the data is fetched
  //   })
  // }, [])

  const state = useSelector((state) => state);
  console.log("Supervisor Pipeline State - ", state);

  const tokenExpired = state.supervisorReports.data?.detail || "";
  const TOKEN_KEY = "my-jwt";
  if (tokenExpired == "Signature has expired.") {
    console.log("In token expired condition");
    AsyncStorage.removeItem(TOKEN_KEY);
    onLogout;
    location.reload();
  }

  const allFields = state.supervisorReports.data?.message || [];
  const updatedOn = state.supervisorReports.data?.updated_on || "";
  dispatch(setSupervisorUpdatedOn(updatedOn));

  // console.log('Supervisor Updated On - ', updatedOn)
  console.log("Supervisor Pipeline All Fields - ", allFields);

  const branchFilter =
    (state.filter.data && state.filter.data.branch_data) || [];

  console.log("Filter Data Response - ", branchFilter);

  const branchZoneFilter = branchFilter.zone;
  const branchStateFilter = branchFilter.state;
  const branchSupervisorFilter = branchFilter.supervisor_name;
  const branchDetailsFilter = branchFilter.branch_details;

  useEffect(() => {
    if (allFields && allFields.APP_ID) {
      const fieldArrays = Object.keys(allFields.APP_ID).map((key) => {
        let pendingFor = allFields.PENDING_FOR[key];
        if (pendingFor > 24) {
          pendingFor =
            pendingFor +
            " HRS - " +
            "(" +
            Math.floor(pendingFor / 24) +
            " Days)";
        } else {
          pendingFor += " HRS";
        }

        return {
          PENDING_FOR: pendingFor,
          BR_CD: allFields.BR_CD[key],
          SUPERVISOR: allFields.SUPERVISOR[key],
          TYPE: allFields.TYPE[key],
          APP_ID: allFields.APP_ID[key],
          REPEAT_APP_ID: allFields.REPEAT_APP_ID[key],
          APPLICANT_NAME: allFields.APPLICANT_NAME[key],
          REQ_LOAN_AMT: allFields.REQ_LOAN_AMT[key],
          BUSINESS_TYPE: allFields.BUSINESS_TYPE[key],
          BUSINESS_TYPE_1: allFields.BUSINESS_TYPE_1[key],
          PIPELINE_STATUS: allFields.PIPELINE_STATUS[key],
          INS_ENTITY: allFields.INS_ENTITY[key],
          UDYAM_AADHAR: allFields.UDYAM_AADHAR[key],
          CURR_STATUS: allFields.CURR_STATUS[key],
          CURR_STATUS_UPDATED: allFields.CURR_STATUS_UPDATED[key],
          LEGAL_QUERY: allFields.LEGAL_QUERY[key],
          TECHNICAL_QUERY: allFields.TECHNICAL_QUERY[key],
          APP_TECHNICAL_STATUS: allFields.APP_TECHNICAL_STATUS[key],
          APP_LEGAL_STATUS: allFields.APP_LEGAL_STATUS[key],
          BUSINESS_VERIFICATION_COMPLETION:
            allFields.BUSINESS_VERIFICATION_COMPLETION[key],
          CREDIT_DECISIONING_COMPLETION:
            allFields.CREDIT_DECISIONING_COMPLETION[key],
        };
      });

      setDataSource(fieldArrays);
    }
  }, [allFields]);

  // useEffect(() => {
  //   // if (allFields && allFields.stage && allFields.value) {
  //   if (allFields && allFields.APP_ID) {
  //     const fieldArrays = Object.keys(allFields.APP_ID).map(key => ({
  //       PENDING_FOR: allFields.PENDING_FOR[key] + ' HRS',
  //       BR_CD: allFields.BR_CD[key],
  //       SUPERVISOR: allFields.SUPERVISOR[key],
  //       APP_ID: allFields.APP_ID[key],
  //       APPLICANT_NAME: allFields.APPLICANT_NAME[key],
  //       REQ_LOAN_AMT: allFields.REQ_LOAN_AMT[key],
  //       CURR_STATUS: allFields.CURR_STATUS[key],
  //       CURR_STATUS_UPDATED: allFields.CURR_STATUS_UPDATED[key]
  //     }))

  //     setDataSource(fieldArrays)
  //   }
  // }, [allFields])

  console.log("Credit Datasource - ", dataSource);

  const paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    defaultPageSize: 10, // Initial page size
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  const [selectedZone, setSelectedZone] = useState("all");
  const [selectedState, setSelectedState] = useState("all");
  const [selectedSupervisor, setSelectedSupervisor] = useState("all");
  const [selectedBranch, setSelectedBranch] = useState("all");
  const [selectedBranchDetails, setSelectedBranchDetails] = useState([]);

  const handleFilterReset = () => {
    setSelectedZone("all");
    setSelectedState("all");
    setSelectedSupervisor("all");
    setSelectedBranch("all");

    const fieldArrays = Object.keys(allFields.APP_ID).map((key) => {
      let pendingFor = allFields.PENDING_FOR[key];
      if (pendingFor > 24) {
        pendingFor =
          pendingFor + " HRS - " + "(" + Math.floor(pendingFor / 24) + " Days)";
      } else {
        pendingFor += " HRS";
      }

      return {
        PENDING_FOR: pendingFor,
        BR_CD: allFields.BR_CD[key],
        SUPERVISOR: allFields.SUPERVISOR[key],
        APP_ID: allFields.APP_ID[key],
        APPLICANT_NAME: allFields.APPLICANT_NAME[key],
        REQ_LOAN_AMT: allFields.REQ_LOAN_AMT[key],
        BUSINESS_TYPE: allFields.BUSINESS_TYPE[key],
        BUSINESS_TYPE_1: allFields.BUSINESS_TYPE_1[key],
        PIPELINE_STATUS: allFields.PIPELINE_STATUS[key],
        INS_ENTITY: allFields.INS_ENTITY[key],
        UDYAM_AADHAR: allFields.UDYAM_AADHAR[key],
        CURR_STATUS: allFields.CURR_STATUS[key],
        CURR_STATUS_UPDATED: allFields.CURR_STATUS_UPDATED[key],
      };
    });

    setDataSource(fieldArrays);
  };

  const handleFilterChange = () => {
    let filteredBranchDetails = [];
    let filteredBranchDetailsZone = [];
    let filteredBranchDetailsState = [];
    let filteredBranchDetailsSupervisor = [];
    let filteredBranchDetailsBranch = [];
    let filteredData;

    if (
      selectedZone == "all" &&
      selectedState == "all" &&
      selectedSupervisor == "all" &&
      selectedBranch == "all"
    ) {
      console.log("In All data condition - ", allFields);
      const fieldArrays = Object.keys(allFields.APP_ID).map((key) => {
        let pendingFor = allFields.PENDING_FOR[key];
        if (pendingFor > 24) {
          pendingFor =
            pendingFor +
            " HRS - " +
            "(" +
            Math.floor(pendingFor / 24) +
            " Days)";
        } else {
          pendingFor += " HRS";
        }

        return {
          PENDING_FOR: pendingFor,
          BR_CD: allFields.BR_CD[key],
          SUPERVISOR: allFields.SUPERVISOR[key],
          APP_ID: allFields.APP_ID[key],
          APPLICANT_NAME: allFields.APPLICANT_NAME[key],
          REQ_LOAN_AMT: allFields.REQ_LOAN_AMT[key],
          BUSINESS_TYPE: allFields.BUSINESS_TYPE[key],
          BUSINESS_TYPE_1: allFields.BUSINESS_TYPE_1[key],
          PIPELINE_STATUS: allFields.PIPELINE_STATUS[key],
          INS_ENTITY: allFields.INS_ENTITY[key],
          UDYAM_AADHAR: allFields.UDYAM_AADHAR[key],
          CURR_STATUS: allFields.CURR_STATUS[key],
          CURR_STATUS_UPDATED: allFields.CURR_STATUS_UPDATED[key],
        };
      });

      setDataSource(fieldArrays);
    } else {
      filteredBranchDetails = Object.entries(branchFilter.branch_details)
        .filter(([_, branch_details]) => {
          return (
            (selectedZone === "all" || branchFilter.zone[_] === selectedZone) &&
            (selectedState === "all" ||
              branchFilter.state[_] === selectedState) &&
            (selectedSupervisor === "all" ||
              branchFilter.supervisor_name[_] === selectedSupervisor) &&
            (selectedBranch === "all" || branch_details === selectedBranch)
          );
        })
        .map(([index]) => branchFilter.branch_details[index].split("-")[0]);

      console.log("LT filtered branch list", filteredBranchDetails);
      console.log("LT filtered branch list allfields", allFields);

      const formattedData = Object.keys(allFields).reduce((result, key) => {
        result[key] = {};

        Object.keys(allFields[key]).forEach((index) => {
          const branchId = allFields.BRANCH_ID[index];
          if (filteredBranchDetails.includes(branchId)) {
            result[key][index] = allFields[key][index];
          }
        });

        return result;
      }, {});

      console.log("LT filtered all data - ", formattedData);

      const filteredObj = Object.values(formattedData);
      console.log("Filtered Obj - ", filteredObj[0][6]);

      const numRows = Object.keys(filteredObj[0]).length;
      console.log("LT num rows", numRows);
      const formattedVendorData = [];
      const formattedSupData = [];
      const formattedAllData = [];

      // Assuming formattedData, formattedAllData, formattedVendorData, and formattedSupData are defined

      for (let i = 0; i < numRows; i++) {
        console.log("In for loop");
        const data = {};

        for (let j = 0; j < filteredObj.length; j++) {
          console.log("In for loop 2");
          const fieldValues = Object.values(filteredObj[j]); // Use Object.values() here
          const fieldName = Object.keys(formattedData)[j];
          console.log("Field name - ", fieldName);

          if (fieldName === "PENDING_FOR") {
            console.log("Filter obj if 1 - ", fieldValues[i]);
            if (fieldValues[i] > 24) {
              console.log("Filter obj if 2 - ", fieldValues[i]);
              data[fieldName] =
                fieldValues[i] +
                " HRS - " +
                "(" +
                Math.floor(fieldValues[i] / 24) +
                " Days)";
            } else {
              console.log("Filter obj else 1 - ", fieldValues[i]);
              data[fieldName] = fieldValues[i] + " HRS";
            }
          } else if (fieldName == "LEGAL_QUERY") {
            if (fieldValues[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldValues[j][i])
              );
              const FeedbackData = JSON.parse(fieldValues[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
          } else if (fieldName == "TECHNICAL_QUERY") {
            if (fieldValues[j][i] != "NULL") {
              console.log(
                "Latest Feedback In legal query condition",
                JSON.parse(fieldValues[j][i])
              );
              const FeedbackData = JSON.parse(fieldValues[j][i]);
              if (FeedbackData && Array.isArray(FeedbackData)) {
                FeedbackData.sort(
                  (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                );
                const latestFeedback = FeedbackData[0].Feedback;
                console.log("Latest Feedback - ", latestFeedback);
                // data[fieldName] = latestFeedback
                const feedbackContent = showMore
                  ? latestFeedback
                  : `${latestFeedback} `;
                const showMoreLink = (
                  <a href="#" onClick={() => handleShowMoreClick(FeedbackData)}>
                    show more
                  </a>
                );
                // const showMoreLink = <a href="#" onClick={() => setShowMore(!showMore)}>show more</a>;
                data[fieldName] = (
                  <>
                    {feedbackContent}
                    {showMoreLink}
                  </>
                );
              } else {
                console.log("Invalid data format. Expected a non-null array.");
              }
            }
            // console.log('In technical query condition')
          } else {
            console.log("Filter obj else 2 - ", fieldValues[i]);
            data[fieldName] = fieldValues[i];
          }
        }

        console.log("In for loop data", data);
        formattedAllData.push(data);
        console.log("LT all filtered data", formattedAllData);

        formattedSupData.push(data); // Add data to formattedSupData
      }

      console.log("LT Branch Data", formattedSupData);

      setDataSource(formattedSupData);
    }
  };

  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredSupervisors, setFilteredSupervisors] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);

  console.log("If branchStateFilter is defined - ", branchStateFilter);

  const handleZoneChange = (value) => {
    setSelectedZone(value);
    if (selectedState != "all") {
      setSelectedState("all");
    }
    if (selectedSupervisor != "all") {
      setSelectedSupervisor("all");
    }
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    if (value === "all") {
      console.log("In zone all condition");
      const uniqueValues = [...new Set(Object.values(branchStateFilter))];
      const uniqueValuesSupervisor = [
        ...new Set(Object.values(branchSupervisorFilter)),
      ];
      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredStates(uniqueValues);
      setFilteredSupervisors(uniqueValuesSupervisor);
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const statesForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchStateFilter[index] : null
            )
            .filter((state) => state !== null)
        ),
      ];

      const supervisorsForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchSupervisorFilter[index] : null
            )
            .filter((supervisor_name) => supervisor_name !== null)
        ),
      ];

      const branchesForSelectedZone = [
        ...new Set(
          Object.values(branchZoneFilter)
            .map((zone, index) =>
              zone === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredStates(statesForSelectedZone);
      setFilteredSupervisors(supervisorsForSelectedZone);
      setFilteredBranch(branchesForSelectedZone);
    }
  };

  const handleStateChange = (value) => {
    if (selectedSupervisor != "all") {
      setSelectedSupervisor("all");
    }
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    setSelectedState(value);

    if (value === "all") {
      console.log("In branch all condition");
      const uniqueValuesSupervisor = [
        ...new Set(Object.values(branchSupervisorFilter)),
      ];
      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredSupervisors(uniqueValuesSupervisor);
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const supervisorsForSelectedState = [
        ...new Set(
          Object.values(branchStateFilter)
            .map((state, index) =>
              state === value ? branchSupervisorFilter[index] : null
            )
            .filter((supervisor_name) => supervisor_name !== null)
        ),
      ];

      const branchesForSelectedState = [
        ...new Set(
          Object.values(branchStateFilter)
            .map((state, index) =>
              state === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredSupervisors(supervisorsForSelectedState);
      setFilteredBranch(branchesForSelectedState);
    }
  };

  const handleSupervisorChange = (value) => {
    if (selectedBranch != "all") {
      setSelectedBranch("all");
    }
    setSelectedSupervisor(value);

    if (value === "all") {
      console.log("In supervisor all condition");

      const uniqueValuesBranches = [
        ...new Set(Object.values(branchDetailsFilter)),
      ];
      setFilteredBranch(uniqueValuesBranches);
    } else {
      const branchesForSelectedSupervisor = [
        ...new Set(
          Object.values(branchSupervisorFilter)
            .map((supervisor_name, index) =>
              supervisor_name === value ? branchDetailsFilter[index] : null
            )
            .filter((branch_details) => branch_details !== null)
        ),
      ];

      setFilteredBranch(branchesForSelectedSupervisor);
    }
  };

  const handleBranchChange = (value) => {
    setSelectedBranch(value);
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
  };

  const filterDataSource = () => {
    let filteredData = dataSource;

    if (selectedStage !== "all") {
      filteredData = filteredData.filter(
        (item) => item.STAGE === Number(selectedStage)
      );
    }

    if (selectedProduct !== "all") {
      filteredData = filteredData.filter(
        (item) => item.PRODUCT === selectedProduct
      );
    }

    if (selectedStatus !== "all") {
      filteredData = filteredData.filter(
        (item) => item.CURR_STATUS === selectedStatus
      );
    }

    if (selectedMobile !== "") {
      filteredData = filteredData.filter((item) =>
        item.APPLICANT_MOBILE.includes(selectedMobile)
      );
    }

    return filteredData;
  };

  const handleShowMoreClick = (e) => {
    setShowMoreModalVisible(true);
    setShowMoreData(e);
    console.log("Show more on click", e);
  };

  const handleCancel = () => {
    setShowMoreModalVisible(false);
  };

  return (
    <>
      {jwt_payload_group == "1" || jwt_payload_group == "3" ? null : (
        <div


          className="filters"
          style={{
            marginBottom: 20,
            display: "flex",
            flexWrap: "wrap",
            gap: 15,
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>Zone: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Zone"
              value={selectedZone}
              onChange={handleZoneChange}
            // onChange={handleFilterChange(value, 'all', 'all', 'all')}
            >
              <Option value="all">All</Option>
              {branchZoneFilter &&
                Object.values(branchZoneFilter)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((zone, index) => (
                    <Option key={index} value={zone}>
                      {zone}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>State: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select State"
              value={selectedState}
              onChange={handleStateChange}
            >
              <Option value="all">All</Option>
              {filteredStates.length !== 0
                ? filteredStates.map((state, index) => (
                  <Option key={index} value={state}>
                    {state}
                  </Option>
                ))
                : branchStateFilter &&
                Object.values(branchStateFilter)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )
                  .map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>Supervisor: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Supervisor"
              value={selectedSupervisor}
              onChange={handleSupervisorChange}
            >
              <Option value="all">All</Option>
              {filteredSupervisors.length !== 0
                ? filteredSupervisors.map((supervisor_name, index) => (
                  <Option key={index} value={supervisor_name}>
                    {supervisor_name}
                  </Option>
                ))
                : branchSupervisorFilter &&
                Object.values(branchSupervisorFilter)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )
                  .map((supervisor_name, index) => (
                    <Option key={index} value={supervisor_name}>
                      {supervisor_name}
                    </Option>
                  ))}
            </Select>
          </div>

          <div style={{ flex: "1 1 200px" }}>
            <label>
              <strong>Branch: </strong>
            </label>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Branch"
              value={selectedBranch}
              onChange={handleBranchChange}
            >
              <Option value="all">All</Option>
              {filteredBranch.length !== 0
                ? filteredBranch.map((branch_details, index) => (
                  <Option key={index} value={branch_details}>
                    {branch_details}
                  </Option>
                ))
                : branchDetailsFilter &&
                Object.values(branchDetailsFilter)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )
                  .map((branch_details, index) => (
                    <Option key={index} value={branch_details}>
                      {branch_details}
                    </Option>
                  ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              gap: 50,
              marginTop: 10,
              marginStart: "6rem",
            }}
          >
            <MyBtn
              onClick={handleFilterChange}
              style={{
                width: 100,
                height: 40,
                margin: "auto",
              }}
            >
              Apply Filter
            </MyBtn>
            <button
              onClick={handleFilterReset}
              size="medium"
              style={{
                borderRadius: "20px",
                background: "white",
                color: "black",
                fontWeight: "600",
                padding: "0 1.2rem",
                color: "blue",
                border: "solid blue 2px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
      <div className="tabled">
        <Card
          layout="horizontal"
          bordered={true}
          className="criclebox tablespace mb-24"
          title="Supervisor Visit Report"
          centered
          style={{ width: "100%", marginTop: "10px" }}
          extra={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              {/* {jwt_payload_group == '2' || jwt_payload_group == '3' ? null : ( */}
              <StyledCSVLink data={dataSource} onClick={() => { }}>
                Download Report
              </StyledCSVLink>
              {/* )} */}
            </div>
          }
        >
          <div className="table-responsive">
            {loading ? (
              <div className="spinner-container">
                {/* <Spin size='large' /> */}
                <HashLoader color="#f57171" />
              </div>
            ) : (
              <StyledTable
                // onRow={(record, rowIndex) => ({
                //   onClick: () => handleRowClick(record)
                // })}
                columns={columns}
                dataSource={dataSource}
                pagination={paginationOptions}
              />
            )}
          </div>
        </Card>
        <Modal
          visible={showMoreModalVisible}
          onCancel={handleCancel}
          width={1300}
          footer={null}
        >
          <div
            style={{
              maxHeight: "850px", // Adjust the max height as needed
              overflowY: "auto",
            }}
          >
            {showMoreData?.length > 0 && (
              <p>
                <strong>LEGAL QUERY TRAIL</strong>
                <br />
                <br />
              </p>
            )}
            {showMoreData?.length > 0 &&
              showMoreData.map((trailItem, index) => (
                <div key={index}>
                  <Tag color="blue">{trailItem.DateTime}</Tag> <br /> <br />
                  {/* <ArrowRightOutlined />
              <Tag color='blue' style={{marginLeft: 8}}>{trailItem.Feedback}</Tag>
              <Tag color='green' style={{marginLeft: 8}}>{trailItem.Feedback}</Tag> */}
                  <strong>Stage:</strong>{" "}
                  <div
                    style={{
                      maxHeight: "90px", // Adjust the max height as needed
                      maxWidth: "800px",
                      marginTop: "10px",
                      overflowY: "auto",
                      border: "1px solid #e8e8e8",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {trailItem.Stage}
                  </div>{" "}
                  <br />
                  <strong>Feedback: </strong>
                  <div
                    style={{
                      maxHeight: "90px", // Adjust the max height as needed
                      maxWidth: "800px",
                      marginTop: "10px",
                      overflowY: "auto",
                      border: "1px solid #e8e8e8",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {trailItem.Feedback}
                  </div>
                  <Divider />
                </div>
              ))}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default CreditPipeline;