import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';

// Utility function to create query params string
const createQueryParams = (page, pageSize, filters) => {
  const params = new URLSearchParams();

  if (page) params.append('page', page);
  if (pageSize) params.append('page_size', pageSize);
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) params.append(key, filters[key]);
    });
  }

  return params.toString();
};

// Define async thunks for fetching data from each endpoint with optional query parameters
export const fetchRejectData = createAsyncThunk(
  'collectionData/fetchRejectData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/reject/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchSuccessData = createAsyncThunk(
  'collectionData/fetchSuccessData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/success/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchPendingData = createAsyncThunk(
  'collectionData/fetchPendingData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/pending/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);
// export const fetchPendingBaData = createAsyncThunk(
//   'collectionData/fetchPendingBaData',
//   async ({ jwtBearer,filters } = {}) => {
//     const queryParams = createQueryParams( filters);
//     const response = await axios.get(`${BASE_URL}collection/pending/data${queryParams ? `?${queryParams}` : ''}`, {
//       headers: {
//         Authorization: `Bearer ${jwtBearer}`
//       }
//     });
//     return response.data;
//   }
// );

export const fetchPendingBaData = createAsyncThunk(
  'collectionReport/fetchPendingBaData',
  async ({ jwtBearer, filters }, { rejectWithValue }) => {
    try {
      // Ensure filters are formatted correctly
      const response = await axios.get(`${BASE_URL}collection/pending/data`, {
        params: {
          ...filters,
        },
        headers: {
          Authorization: `Bearer ${jwtBearer}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPendingForApprovalData = createAsyncThunk(
  'collectionData/fetchPendingForApprovalData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    const queryParams = createQueryParams(page, pageSize, filters);
    const response = await axios.get(`${BASE_URL}collection/pending-approval/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchRejectFilteredData = createAsyncThunk(
  'collectionData/fetchRejectFilteredData',
  async ({ jwtBearer, filters } = {}) => {
    const queryParams = createQueryParams(null, null, filters);
    const response = await axios.get(`${BASE_URL}collection/reject/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchSuccessFilteredData = createAsyncThunk(
  'collectionData/fetchSuccessFilteredData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    // const queryParams = createQueryParams(page, pageSize, filters);
    const queryParams = createQueryParams(null, null, filters);
    const response = await axios.get(`${BASE_URL}collection/success/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchPendingFilteredData = createAsyncThunk(
  'collectionData/fetchPendingFilteredData',
  async ({ jwtBearer, filters } = {}) => {
    const queryParams = createQueryParams(null, null, filters);
    const response = await axios.get(`${BASE_URL}collection/pending/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

export const fetchPendingForApprovalFilteredData = createAsyncThunk(
  'collectionData/fetchPendingForApprovalFilteredData',
  async ({ jwtBearer, page, pageSize, filters } = {}) => {
    // const queryParams = createQueryParams(page, pageSize, filters);
    const queryParams = createQueryParams(null, null, filters);
    const response = await axios.get(`${BASE_URL}collection/pending-approval/data${queryParams ? `?${queryParams}` : ''}`, {
      headers: {
        Authorization: `Bearer ${jwtBearer}`
      }
    });
    return response.data;
  }
);

const initialState = {
  rejectData: null,
  successData: null,
  pendingData: null,
  pendingForApprovalData: null,
  rejectFilteredData: null,
  successFilteredData: null,
  pendingFilteredData: null,
  pendingForApprovalFilteredData: null,
  rejectCount: null,
  successCount: null,
  pendingCount: null,
  pendingForApprovalCount: null,
  errorReject: null,
  errorSuccess: null,
  errorPending: null,
  errorPendingForApproval: null,
  errorRejectFiltered: null,
  errorSuccessFiltered: null,
  errorPendingFiltered: null,
  errorPendingForApprovalFiltered: null,
  isLoadingSuccess: false,
  isLoadingReject: false,
  isLoadingPending: false,
  isLoadingPendingForApproval: false,
  isLoadingRejectFiltered: false,
  isLoadingSuccessFiltered: false,
  isLoadingPendingFiltered: false,
  isLoadingPendingForApprovalFiltered: false,

  isLoadingPendingBA: true,
  errorPendingBA: null,
  pendingBAData: null
};

// Create the slice
const collectionDataSlice = createSlice({
  name: 'collectionData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle thunk states for reject data
      .addCase(fetchRejectData.pending, (state) => {
        state.isLoadingReject = true;
        state.errorReject = null; // Clear previous errors
      })
      .addCase(fetchRejectData.fulfilled, (state, action) => {
        state.isLoadingReject = false;
        state.rejectData = action.payload.reject.data;
        state.rejectCount = action.payload.reject.total_count;
      })
      .addCase(fetchRejectData.rejected, (state, action) => {
        state.isLoadingReject = false;
        state.errorReject = action.error.message;
      })

      // Handle thunk states for success data
      .addCase(fetchSuccessData.pending, (state) => {
        state.isLoadingSuccess = true;
        state.errorSuccess = null; // Clear previous errors
      })
      .addCase(fetchSuccessData.fulfilled, (state, action) => {
        state.isLoadingSuccess = false;
        state.successData = action.payload.success.data;
        state.successCount = action.payload.success.total_count;
      })
      .addCase(fetchSuccessData.rejected, (state, action) => {
        state.isLoadingSuccess = false;
        state.errorSuccess = action.error.message;
      })

      // Handle thunk states for pending data
      .addCase(fetchPendingData.pending, (state) => {
        state.isLoadingPending = true;
        state.errorPending = null; // Clear previous errors
      })
      .addCase(fetchPendingData.fulfilled, (state, action) => {
        state.isLoadingPending = false;
        state.pendingData = action.payload.pending.data;
        state.pendingCount = action.payload.pending.total_count;
      })
      .addCase(fetchPendingData.rejected, (state, action) => {
        state.isLoadingPending = false;
        state.errorPending = action.error.message;
      })




      // Handle thunk states for pending ba data
      .addCase(fetchPendingBaData.pending, (state) => {
        state.isLoadingPendingBA = true;
        state.errorPendingBA = null; // Clear previous errors
      })
      .addCase(fetchPendingBaData.fulfilled, (state, action) => {
        state.isLoadingPendingBA = false;
        state.pendingBAData = action.payload.pending.data;

      })
      .addCase(fetchPendingBaData.rejected, (state, action) => {
        state.isLoadingPendingBA = false;
        state.errorPendingBA = action.error.message;
      })

      // Handle thunk states for pending for approval data
      .addCase(fetchPendingForApprovalData.pending, (state) => {
        state.isLoadingPendingForApproval = true;
        state.errorPendingForApproval = null; // Clear previous errors
      })
      .addCase(fetchPendingForApprovalData.fulfilled, (state, action) => {
        state.isLoadingPendingForApproval = false;
        state.pendingForApprovalData = action.payload.pending_for_approval.data;
        state.pendingForApprovalCount = action.payload.pending_for_approval.total_count
      })
      .addCase(fetchPendingForApprovalData.rejected, (state, action) => {
        state.isLoadingPendingForApproval = false;
        state.errorPendingForApproval = action.error.message;
      })

      // Handle thunk states for filtered reject data
      .addCase(fetchRejectFilteredData.pending, (state) => {
        state.isLoadingRejectFiltered = true;
        state.errorRejectFiltered = null; // Clear previous errors
      })
      .addCase(fetchRejectFilteredData.fulfilled, (state, action) => {
        state.isLoadingRejectFiltered = false;
        state.rejectFilteredData = action.payload.reject.data;

      })
      .addCase(fetchRejectFilteredData.rejected, (state, action) => {
        state.isLoadingRejectFiltered = false;
        state.errorRejectFiltered = action.error.message;
      })

      // Handle thunk states for filtered success data
      .addCase(fetchSuccessFilteredData.pending, (state) => {
        state.isLoadingSuccessFiltered = true;
        state.errorSuccessFiltered = null; // Clear previous errors
      })
      .addCase(fetchSuccessFilteredData.fulfilled, (state, action) => {
        state.isLoadingSuccessFiltered = false;
        state.successFilteredData = action.payload.success.data;
      })
      .addCase(fetchSuccessFilteredData.rejected, (state, action) => {
        state.isLoadingSuccessFiltered = false;
        state.errorSuccessFiltered = action.error.message;
      })

      // Handle thunk states for filtered pending data
      .addCase(fetchPendingFilteredData.pending, (state) => {
        state.isLoadingPendingFiltered = true;
        state.errorPendingFiltered = null; // Clear previous errors
      })
      .addCase(fetchPendingFilteredData.fulfilled, (state, action) => {
        state.isLoadingPendingFiltered = false;
        state.pendingFilteredData = action.payload.pending.data;

      })
      .addCase(fetchPendingFilteredData.rejected, (state, action) => {
        state.isLoadingPendingFiltered = false;
        state.errorPendingFiltered = action.error.message;
      })

      // Handle thunk states for filtered pending for approval data
      .addCase(fetchPendingForApprovalFilteredData.pending, (state) => {
        state.isLoadingPendingForApprovalFiltered = true;
        state.errorPendingForApprovalFiltered = null; // Clear previous errors
      })
      .addCase(fetchPendingForApprovalFilteredData.fulfilled, (state, action) => {
        state.isLoadingPendingForApprovalFiltered = false;
        state.pendingForApprovalFilteredData = action.payload.pending_for_approval.data;
        state.pendingForApprovalFilteredCount = action.payload.pending_for_approval.total_count;
      })
      .addCase(fetchPendingForApprovalFilteredData.rejected, (state, action) => {
        state.isLoadingPendingForApprovalFiltered = false;
        state.errorPendingForApprovalFiltered = action.error.message;
      });
  }
});

export default collectionDataSlice.reducer;