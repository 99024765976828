import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useAuth } from '../../../context/AuthContext'
// import { fetchCollectionData } from '../../../redux/slice/collectionReportSlice'
import { fetchAllBA } from '../../../redux/slice/getAllBASlice'
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Typography,
  Divider,
  Form,
  Select,
  Row,
  Col
} from 'antd'
import {
  InfoCircleOutlined
} from '@ant-design/icons'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { CSVLink } from 'react-csv'
import { updateCollectionBA } from '../../../redux/slice/updateCollectionBA'

const collectionData = (props) => {
  const dispatch = useDispatch()
  const [dataSource, setDataSource] = useState([])
  const [pendingDataSource, setPendingDataSource] = useState([])
  const [rejectDataSource, setRejectDataSource] = useState([])
  const [PFADataSource, setPFADataSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [reassignModalVisible, setReassignModalVisible] = useState(false)
  const [currentBA, setCurrentBA] = useState('')
  const [currentAppID, setCurrentAppID] = useState('')
  const [baList, setbaList] = useState({})
  const [selectedBA, setSelectedBA] = useState('')
  const [appID, setAppID] = useState('')
  const [currentBACode, setCurrentBACode] = useState('')
  const [form] = Form.useForm();
  const [reassignType, setReassignType] = useState('')
  const [remarksValue, setRemarksValue] = useState('')
  const [remarksValueOther, setRemarksValueOther] = useState('')
  const [modalData, setModalData] = useState([]);
  const [modalDataSingle, setModalDataSingle] = useState([]);

  const [modalColumns, setModalColumns] = useState([]);
  const [modalPagination, setModalPagination] = useState({
    pageSize: 10,
    current: 1,
    total: modalData.length,
  });



  const { authState, onLogout } = useAuth()

  const getFirstBranch = (payload) => {
    // Ensure payload is not empty or improperly formatted
    if (!payload || payload.length < 3) {
      return null; // or some default value or error handling
    }

    // Remove the parentheses and split
    var branchValues = payload.slice(1, -1);
    var branchesArray = branchValues.split(',');

    // Return the first value or null if array is empty
    return branchesArray.length > 0 ? branchesArray[0].trim() : null;
  }




  var token_decoded = authState.token
  var jwtBearer = authState.token
  var jwt_payload = ''
  var jwt_payload_group = []
  var jwt_payload_user = ''
  var jwt_payload_role = ''
  var jwt_payload_branch = ''
  if (jwtBearer) {
    console.log('Token decoded')
    jwt_payload = jwt_decode(jwtBearer)
    console.log('Token payload', jwt_payload)
    // jwt_payload_group = jwt_payload.group_id
    jwt_payload_group = props.userDetails?.group_id
    jwt_payload_user = jwt_payload.user_id
    // jwt_payload_role = jwt_payload.role
    jwt_payload_role = props.userDetails?.role
    // jwt_payload_branch = jwt_payload.branch_id
    jwt_payload_branch = props.userDetails?.branch_id
  }

  return (
    <>
      <center>
        <p style={{ fontSize: '26px', textAlign: 'center', marginBottom: '10px', color: "blue" }}>
          Reach out to IT support to get the password for this dashboard.
        </p>
        <div style={{ marginTop: '20px', fontWeight: 790, fontSize: '30px' }} className='zohoContainer'>
          <iframe className='zoho-responsive-iframe'
            src={
              jwt_payload_role === 'Supervisor'
                // ? `https://analytics.zoho.in/open-view/249291000003666114/a5cd0afb10300b51ec70276cc886a191?ZOHO_CRITERIA=%22branch_info_master%22.%22supervisor_emp_code%22='${jwt_payload_user}'`
                ? `https://analytics.zoho.in/open-view/249291000004505001/a6dc8080260c2eeb1575a02b4af15ef2?ZOHO_CRITERIA=%22branch_info_master%22.%22supervisor_emp_code%22='${jwt_payload_user}'`
                : "https://analytics.zoho.in/open-view/249291000004505001/a6dc8080260c2eeb1575a02b4af15ef2"
              // : "https://analytics.zoho.in/open-view/249291000003666114/a5cd0afb10300b51ec70276cc886a191"
            }
          ></iframe>
        </div>
      </center >
    </>
  )
}

export default collectionData
