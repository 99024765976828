import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import ConfettiExplosion from 'react-confetti-explosion';
import {
  Layout,
  Drawer,
  Affix,
  theme,
  Button,
  Menu,
  Divider,
  Avatar,
  Modal,
  Popover,
  Row,
  Col,
  Tooltip,
  Watermark,
  Form,
  Input,
  Card,
  message,
} from "antd";
import Sidenav from "./Sidenav";
// import Header from './Header'
import React from "react";
import { AppRegistry } from "react-native";
import App from "../../../App";
import logo from "../../../assets/logo.png";
import logo_rounded from "../../../assets/logo_rounded.png";

import Diwali_Final from "../../../assets/Diwali_Final.jpg";
import { updateUserPassword } from "../../redux/slice/updateUserPassword";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  RobotOutlined,
  VideoCameraOutlined,
  FileProtectOutlined,
  HomeOutlined,
  DollarOutlined,
  MobileOutlined,
  AreaChartOutlined,
  LaptopOutlined,
  DesktopOutlined,
  PieChartOutlined
} from "@ant-design/icons";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { NavLink } from "react-router-dom";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import PermissionsGate from "../PermissionsGate";
import { SCOPES } from "../PermissionsMap";
import { useAuth } from "../../context/AuthContext";
import jwt_decode from "jwt-decode";
import { setCollapsed } from "../../redux/slice/sidenavCollapseSlice";
import { fetchUserDetails } from "../../redux/slice/getUserDetails";
// import Footer from "./Footer";

// const { Header: AntHeader, Content, Sider } = Layout
const { Header, Sider, Content } = Layout;

function Main({ children }) {
  const { authState } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");
  const [confettiVisible, setConfettiVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showResetButton, setShowResetButton] = useState(false);
  const [userDetails, setUserDetails] = useState(null)
  // const menuColor = '#250000'
  const menuColor = "linear-gradient(to right, #141e30, #243b55)";
  const menuColorInvert = "linear-gradient(to right, #243b55, #141e30)";

  useEffect(() => {
    // Extract the page name from the pathname (remove the leading '/')
    const pageName = location.pathname.substring(1);
    setCurrentPage(pageName);
  }, [location]);

  var token_decoded = authState.token;
  console.log("Main Token - ", token_decoded);

  var jwtBearer = authState.token

  useEffect(() => {
    // Only execute if jwtBearer and user_id are available
    if (jwtBearer && jwt_payload?.user_id) {
      // Create an async function to handle the async operation
      const fetchData = async () => {
        try {
          // Await the user response
          const userResponse = await dispatch(fetchUserDetails({ jwtBearer, emp_code: jwt_payload.user_id }));

          // Log the extracted data
          console.log('User Details Main - ', userResponse.payload?.message);
          setUserDetails(userResponse.payload?.message);

        } catch (error) {
          console.error('Error fetching user details: ', error);
        }
      };

      // Call the async function
      fetchData();
    }

    // Add dependencies that trigger this effect
  }, [jwtBearer, jwt_payload?.user_id]); // Only re-run the effect if jwtBearer or user_id changes

  var jwt_payload = "";
  var jwt_payload_username = "";
  var jwt_payload_userid = "";
  var jwt_role = "";
  if (token_decoded) {
    jwt_payload = jwt_decode(token_decoded);
    // jwt_payload_username = jwt_payload.username;
    jwt_payload_username = userDetails?.username;
    jwt_payload_userid = jwt_payload.user_id;
    // jwt_role = jwt_payload.role;
    jwt_role = userDetails?.role;
  }
  console.log("session timeout check main");
  const { onLogout } = useAuth();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  // const [sidenavType, setSidenavType] = useState('transparent')
  const [sidenavType, setSidenavType] = useState("#E7EBEC");
  const [fixed, setFixed] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(defaultSelectedKey);
  const [passwordChangeModalVisible, setPasswordChangeModalVisible] =
    useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  AppRegistry.registerComponent("YourAppName", () => App);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  const [collapsed, setCollapsed] = useState(false);
  // const collapseState = useSelector(state => state.sidenavCollapse)
  const collapseState = useSelector((state) => state.sidenavCollapse);
  console.log("Sidenav Collapse State - ", collapseState);
  useEffect(() => {
    if (collapseState === "collapsed") {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [collapseState]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigation = useNavigation();

  const handleMenuItemPress = (screenName) => {
    navigation.navigate(screenName);
  };
  const Stack = createNativeStackNavigator();

  // const defaultSelectedKey = page || 'Pipeline'
  // const defaultSelectedKey = 'Reports'
  // const defaultSelectedKey = 'Home'
  const page = pathname.replace("/", "");

  // const Collection = [
  // <svg
  //   width='20'
  //   height='20'
  //   viewBox='0 0 20 20'
  //   fill='none'
  //   xmlns='http://www.w3.org/2000/svg'
  //   key={0}
  // >
  //   <path
  //     fillRule='evenodd'
  //     clipRule='evenodd'
  //     d='M2 7V14C2 14.5523 2.44772 15 3 15H17C17.5523 15 18 14.5523 18 14V7C18 6.44772 17.5523 6 17 6H3C2.44772 6 2 6.44772 2 7ZM10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11ZM5 7H15V14H5V7Z'
  //     // fill={color}
  //   ></path>
  // </svg>
  //   <UploadOutlined />
  // ]
  // const defaultSelectedKey = pathname || 'Reports'
  // const defaultSelectedKey = pathname || '2'
  // const defaultSelectedKey = '2'
  const getDefaultSelectedKey = (pathname) => {
    switch (pathname) {
      case "/Home":
        return "1";
      case "/Reports":
        return "2";
      case "/MIS":
        return "3";
      case "/Collection":
        return "4";
      case "/PDDModule":
        return "5";
      case "/CMASupport":
        return "6";
      case "/ITInventory":
        return "7";
      case "/Notifications":
        return "8";
      case "/CMA":
        return "9";
      // case '/Collection':
      //   return '7'
      case "/DigitalJourney":
        return "10";
      case './ZohoMIS':
        return '11'
      case "/Adminusers":
        return "12";
      default:
        return "1"; // Set a default value in case the pathname doesn't match any of the cases
    }
  };

  // Get the defaultSelectedKey based on the current pathname
  const defaultSelectedKey = getDefaultSelectedKey(location.pathname);
  console.log("Current Page Sidenav - ", defaultSelectedKey);

  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    // const updatedSelectedKeys = [pathname || "Reports"];
    const updatedSelectedKeys = [pathname || "Home"];
    setSelectedKeys(updatedSelectedKeys);
  }, [pathname]);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const showPopover = () => {
    setPopoverVisible(true);
  };

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  const [signOutModalVisible, setSignOutModalVisible] = useState(false);

  const showSignOutModal = () => {
    setSignOutModalVisible(true);
    hidePopover(); // Hide the popover when opening the sign-out confirmation modal
  };

  const showPasswordChangeModal = () => {
    setPasswordChangeModalVisible(true);
    hidePopover(); // Hide the popover when opening the sign-out confirmation modal
  };

  const hideSignOutModal = () => {
    setSignOutModalVisible(false);
    setPasswordChangeModalVisible(false);
  };

  const handleConfetti = () => {
    setConfettiVisible(true);
    setTimeout(() => {
      setConfettiVisible(false);
    }, 3000); // Adjust the duration as needed
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (reNewPassword != "") {
      if (reNewPassword !== e.target.value) {
        setPasswordMatchError("Passwords do not match");
        setShowResetButton(false);
      } else {
        setPasswordMatchError("");
        setShowResetButton(true);
      }
    }
  };

  const handleReNewPasswordChange = (e) => {
    setReNewPassword(e.target.value);
    const reEnterPassword = e.target.value;
    // Check if the entered password matches the new password
    if (reEnterPassword !== newPassword) {
      setPasswordMatchError("Passwords do not match");
      setShowResetButton(false);
    } else {
      setPasswordMatchError("");
      setShowResetButton(true);
    }
  };

  const handleResetPassword = async () => {
    try {
      const requestBody = {
        userid: jwt_payload_userid,
        new_password: newPassword,
        old_password: oldPassword,
        category: "user",
      };

      const response = await dispatch(
        updateUserPassword({ jwt_token: authState.token, requestBody })
      );

      console.log("Password reset response - ", response);
      // Reset the form and mode after submission
      if (response.payload?.code == 200) {
        setPasswordChangeModalVisible(false);
        AsyncStorage.removeItem("my-jwt");
        setTimeout(() => {
          alert(
            "You will be redirected to the login screen shortly. Please use your new password to login"
          );
        }, 2000); // Delay rendering by 500ms
        setTimeout(() => {
          // message.success("Password changed successfully");
          window.location.reload();
        }, 5000); // Delay rendering by 500ms
      }
      // setUploadSuccess(true)
      // message.success("Password changed successfully");
      alert(response.payload?.message);
    } catch (error) {
      console.error("Error in change password:", error);
      // setUploadFailedError(true)
      message.error("Unknown error occurred while changing password");
    }
  };

  return (
    // <Layout>
    //   <Sidenav />
    //   {/* <Layout>
    //     <Content className='content-ant'>{children}</Content>
    //   </Layout> */}
    // </Layout>
    <Layout>
      <Sider
        style={{
          background: menuColor,
          zIndex: 1,
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      // width={150}
      >
        {/* <div className='demo-logo-vertical' /> */}
        {/* <div
          className='brand'
          // style={{ marginBottom: '10px', marginTop }}
          style={{ margin: '30px', justifyContent: 'center' }}
        >
          {collapsed ? (
            <img
              src={logo}
              alt=''
              style={{
                // padding: 8,

                // width: 24,
                // {collapsed}
                height: 18
              }}
            />
          ) : (
            <img
              src={logo}
              alt=''
              style={{
                // padding: 8,

                // width: 24,
                // {collapsed}
                height: 48
              }}
            />
          )}
        </div> */}
        <div
          className="brand"
          style={{
            margin: "30px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!collapsed && (
            <>
              <img
                src={logo_rounded}
                alt=""
                style={{
                  height: 48,
                  marginLeft: "8px",
                  // borderRadius: '15%'
                }}
              />
            </>
          )}
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "26px", color: "white" }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: "26px", color: "white" }}
                />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        {/* <Menu
          theme='dark'
          mode='inline'
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '1',
              icon: <UserOutlined />,
              label: 'HOME',
              // onClick={() => handleMenuItemPress('Home')}
              onClick: handleMenuItemPress('Home')
            },
            {
              key: '2',
              icon: <VideoCameraOutlined />,
              label: 'DAILY REPORTS',
              onClick: handleMenuItemPress('Reports')
            },
            {
              key: '3',
              icon: <UploadOutlined />,
              label: 'MANAGE USERS',
              onClick: handleMenuItemPress('Adminusers')
            },
            {
              key: '4',
              icon: <UploadOutlined />,
              label: 'COLLECTION',
              onClick: handleMenuItemPress('Collection')
            }
          ]}
        /> */}
        <Menu
          style={{
            background: menuColor,
          }}
          theme="dark"
          // mode='inline'
          // defaultSelectedKeys={[defaultSelectedKey]}
          defaultSelectedKeys={[defaultSelectedKey]}
        >
          <Menu.Item
            key='1'
            onClick={() => {
              handleMenuItemPress('Home')
            }}
          >
            <NavLink to='/Home'>
              <span
                className='icon'
                style={{
                  background: page === 'Home'
                }}
              >
                {<HomeOutlined style={{ fontSize: '20px' }} />}
              </span>
              {!collapsed && (
                <span className='label' style={{ marginLeft: '8px' }}>
                  HOME
                </span>
              )}
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
            key="10"
            onClick={() => handleMenuItemPress("Admin")}
            title="ADMIN"
          >
            <PermissionsGate scopes={[SCOPES.canViewAD]}>
              <NavLink to="/Admin">
                <span
                  className="icon"
                  style={{
                    fontSize: "2px",
                    background: page === "Admin",
                  }}
                >
                  {<UserOutlined />}
                </span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    ADMIN PANEL
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item> */}
          {jwt_role !== "Support" ? (
            <Menu.Item
              key="2"
              onClick={() => handleMenuItemPress("Reports")}
              title="DAILY REPORTS"
            >
              <PermissionsGate scopes={[SCOPES.canViewReports]}>
                <NavLink to="/Reports">
                  <span
                    className="icon"
                    style={{
                      // background: page === 'Reports' ? color : ''
                      background: page === "Reports",
                    }}
                  >
                    {<FileProtectOutlined style={{ fontSize: '20px' }} />}
                  </span>
                  {/* <span className='label'> DAILY REPORTS</span> */}
                  {!collapsed && (
                    <span className="label" style={{ marginLeft: "8px" }}>
                      DAILY REPORTS
                    </span>
                  )}
                </NavLink>
              </PermissionsGate>
            </Menu.Item>
          ) : null}
          <Menu.Item
            key="3"
            onClick={() => handleMenuItemPress("MIS")}
            title="OPS MIS"
          >
            <PermissionsGate scopes={[SCOPES.canViewMIS]}>
              <NavLink to="/MIS">
                <span
                // className='icon'
                // style={{
                //   background: page === 'Mis'
                // }}
                >
                  {<UploadOutlined style={{ fontSize: '20px' }} />}
                </span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    OPS MIS
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item>

          <Menu.Item
            key="4"
            onClick={() => handleMenuItemPress("Collection")}
            title="Collection"
          >
            <PermissionsGate scopes={[SCOPES.canViewCollection]}>
              <NavLink to="/Collection">
                <span>{<DollarOutlined style={{ fontSize: '20px' }} />}</span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    Collection
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item>

          {/* <Menu.Item
            key="5"
            onClick={() => handleMenuItemPress("pddModule")}
            title="PDD Module"
          >
            <PermissionsGate scopes={[SCOPES.canViewPDD]}>
              <NavLink to="/pddModule">
                <span
                  className="icon"
                  style={{
                    fontSize: "2px",
                    background: page === "pddModule",
                  }}
                >
                  {<RobotOutlined style={{ fontSize: '20px' }} />}
                </span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    PDD Module
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item> */}

          {jwt_role === "Credit-Support" ||
            jwt_role === "Support" ||
            jwt_role === "Operations-Support" ||
            jwt_role === "Admin" ||
            jwt_role === "IT-Support" ||
            jwt_role === "Telecaller-Executive" ||
            jwt_role === "Telecaller-Sup" ? (
            <Menu.Item
              key="6"
              onClick={() => handleMenuItemPress("CMASupport")}
              title="CMASupport"
            >
              <PermissionsGate scopes={[SCOPES.canViewCMATickets]}>
                <NavLink to="/CMASupport">
                  <span>{<LaptopOutlined style={{ fontSize: '20px' }} />}</span>
                  {!collapsed && (
                    <span className="label" style={{ marginLeft: "8px" }}>
                      CUSTOMER SUPPORT
                    </span>
                  )}
                </NavLink>
              </PermissionsGate>
            </Menu.Item>
          ) : null}

          <Menu.Item
            key="7"
            onClick={() => handleMenuItemPress("ITInventory")}
            title="ITInventory"
          >
            <PermissionsGate scopes={[SCOPES.canViewInventory]}>
              <NavLink to="/ITInventory">
                <span>{<DesktopOutlined style={{ fontSize: '20px' }} />}</span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    ASSET INVENTORY
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item>

          {/* <Menu.Item
            key='4'
            onClick={() => handleMenuItemPress('Lead')}
            title='LEAD'
          >
            <PermissionsGate scopes={[SCOPES.canViewLeads]}>
              <NavLink to='/Lead'>
                <span>{<AreaChartOutlined />}</span>
                {!collapsed && (
                  <span className='label' style={{ marginLeft: '8px' }}>
                    LEAD
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item> */}

          {/* <Menu.Item
            key="8"
            onClick={() => handleMenuItemPress("Notifications")}
            title="Notifications"
          >
            <PermissionsGate scopes={[SCOPES.canViewCMA]}>
              <NavLink to="/Notifications">
                <span>{<MobileOutlined style={{ fontSize: '20px' }} />}</span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    CMA Notifications
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item> */}

          {/* <Menu.Item
            key="9"
            onClick={() => handleMenuItemPress("CMA")}
            title="CMA"
          >
            <PermissionsGate scopes={[SCOPES.canViewCMA]}>
              <NavLink to="/CMA">
                <span>{<MobileOutlined style={{ fontSize: '20px' }} />}</span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    CMA
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item> */}

          {/* <Menu.Item
            key='7'
            onClick={() => handleMenuItemPress('Collection')}
            title='COLLECTION'
          >
            <PermissionsGate scopes={[SCOPES.canViewCollection]}>
              <NavLink to='/Collection'>
                <span
                  className='icon'
                  style={{
                    background: page === 'Collection'
                  }}
                >
                  {<DollarOutlined />}
                </span>
                {!collapsed && (
                  <span className='label' style={{ marginLeft: '8px' }}>
                    COLLECTION
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item> */}

          <Menu.Item
            key="10"
            onClick={() => handleMenuItemPress("DigitalJourney")}
            title="DIGITAL JOURNEY"
          >
            <PermissionsGate scopes={[SCOPES.canViewDigitalJourney]}>
              <NavLink to="/DigitalJourney">
                <span
                  className="icon"
                  style={{
                    fontSize: "2px",
                    background: page === "DigitalJourney",
                  }}
                >
                  {<RobotOutlined style={{ fontSize: '20px' }} />}
                </span>
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    DIGITAL JOURNEY
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item>
          {jwt_role === "Supervisor" ||
            jwt_role === "Admin" ? (
            <Menu.Item
              key='11'
              onClick={() => handleMenuItemPress('ZohoMIS')}
              title='ZohoMIS'
            >
              <PermissionsGate scopes={[SCOPES.canViewZohoMIS]}>
                <NavLink to='/ZohoMIS'>
                  <span
                    className='icon'
                    style={{
                      fontSize: '2px',
                      background: page === 'ZohoMIS',
                    }}
                  >
                    {/* {<PieChartOutlined />} */}
                    <PieChartOutlined style={{ fontSize: '20px' }} /> {/* Set the icon size here */}
                  </span>
                  {!collapsed && (
                    <span className='label' style={{ marginLeft: '8px' }}>
                      DASHBOARDS
                    </span>
                  )}
                </NavLink>
              </PermissionsGate>
            </Menu.Item>
          ) : null}

          <Menu.Item
            key="12"
            onClick={() => handleMenuItemPress("Adminusers")}
            title="ADMIN CONSOLE"
          >
            <PermissionsGate scopes={[SCOPES.canViewAdmin]}>
              <NavLink to="/Adminusers">
                <span
                  className="icon"
                  style={{
                    fontSize: "2px",
                    // background: page === 'Adminusers' ? color : ''
                    background: page === "Adminusers",
                  }}
                >
                  {<UserOutlined style={{ fontSize: '20px' }} />}
                </span>
                {/* <span className='label'> MANAGE USERS</span> */}
                {!collapsed && (
                  <span className="label" style={{ marginLeft: "8px" }}>
                    ADMIN CONSOLE
                  </span>
                )}
              </NavLink>
            </PermissionsGate>
          </Menu.Item>
        </Menu>
        <div
          style={{
            flexGrow: 1,
            position: "absolute",
            bottom: 20,
            width: "80%",
          }}
        >
          <div
            style={{
              textAlign: "left",
              marginBottom: 20,
            }}
          >
            {/* <Divider style={{ height: '2px', background: '#fff' }} /> */}

            <Row>
              <Col style={{ marginLeft: 8 }}>
                <span onClick={showPopover}>
                  <Popover
                    overlayStyle={{
                      backgroundColor: "#1f1f1f",
                      color: "#ffffff",
                    }}
                    content={
                      <>
                        <Button
                          type="text"
                          block
                          onClick={() => {
                            hidePopover();
                            handleConfetti();
                          }}
                        >
                          My Profile
                        </Button>
                        <Button
                          type="text"
                          block
                          onClick={showPasswordChangeModal}
                        >
                          Reset Password
                        </Button>
                        <Button type="text" block onClick={showSignOutModal}>
                          Logout
                        </Button>
                      </>
                    }
                    visible={popoverVisible}
                    onVisibleChange={setPopoverVisible}
                    trigger="click"
                  >
                    <Avatar
                      shape="square"
                      size={48}
                      style={{ backgroundColor: "#1890ff", marginBottom: 10 }}
                      icon={<UserOutlined style={{ fontSize: '20px' }} />}
                    />
                  </Popover>
                </span>
              </Col>
              {!collapsed && (
                // <Col style={{ marginLeft: 16, marginTop: 4 }}>
                <Col
                  style={{
                    marginLeft: 16,
                    marginTop: 4,
                    maxWidth: "calc(100% - 76px)",
                  }}
                >
                  {jwt_payload_username && (
                    <Tooltip title={jwt_payload_username}>
                      <p
                        style={{
                          color: "#ffffff",
                          marginTop: 8,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {jwt_payload_username}
                      </p>
                    </Tooltip>
                  )}
                </Col>
              )}
            </Row>
          </div>
        </div>

        <Modal
          visible={signOutModalVisible}
          onCancel={hideSignOutModal}
          footer={[
            <Button key="cancel" onClick={hideSignOutModal}>
              Cancel
            </Button>,
            <Button key="signOut" type="primary" onClick={onLogout}>
              Sign Out
            </Button>,
          ]}
        >
          <p>Are you sure you want to sign out?</p>
        </Modal>
        <Modal
          visible={passwordChangeModalVisible}
          onCancel={hideSignOutModal}
          footer={null}
          width={1300}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Card
                layout="horizontal"
                bordered={true}
                className="criclebox tablespace mb-24"
                // title={
                //   <>
                //     CUSTOMER MOBILE APP TICKETS - OPEN
                //   </>
                // }
                style={{
                  marginTop: "20px",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Form
                  layout="vertical" // Set the form layout to vertical
                  style={{
                    marginTop: "20px",
                    width: 500,
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Form.Item
                    label={<strong>ENTER THE OLD PASSWORD :</strong>}
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the old password.",
                      },
                    ]}
                  >
                    <>
                      <Input
                        style={{ color: "#404040" }}
                        onChange={handleOldPasswordChange}
                      />
                    </>
                  </Form.Item>
                  <Form.Item
                    label={<strong>ENTER THE NEW PASSWORD :</strong>}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the new password.",
                      },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
                        message:
                          "Password must contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character.",
                      },
                    ]}
                  >
                    <>
                      <Input
                        type="password"
                        style={{ color: "#404040" }}
                        onChange={handleNewPasswordChange}
                      />
                    </>
                  </Form.Item>
                  <Form.Item
                    label={<strong>RE-ENTER THE NEW PASSWORD :</strong>}
                    name="reNewPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please re-enter the new password.",
                      },
                    ]}
                  >
                    <>
                      <Input
                        style={{ color: "#404040" }}
                        onChange={handleReNewPasswordChange}
                      />
                    </>
                  </Form.Item>
                  {passwordMatchError && (
                    <div style={{ color: "red", marginLeft: "20px" }}>
                      {passwordMatchError}
                    </div>
                  )}
                  {showResetButton && (
                    <Button type="primary" onClick={handleResetPassword}>
                      Reset Password
                    </Button>
                  )}
                </Form>
              </Card>
            </Col>
            {/* <Col span={8} style={{marginLeft: '40px'}}> */}
            <Col span={12}>
              <Card
                layout="horizontal"
                bordered={true}
                className="criclebox tablespace mb-24"
                // title={
                //   <>
                //     CUSTOMER MOBILE APP TICKETS - OPEN
                //   </>
                // }
                style={{
                  marginTop: "20px",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                  <p>
                    <strong>
                      In order to protect your account, make sure your password
                      meets the following requirements:
                    </strong>
                  </p>
                  <p>1. Must be atleast 8 characters long.</p>
                  <p>
                    2. Must contain at least <strong>one numeric</strong>{" "}
                    character.
                  </p>
                  <p>
                    3. Must contain at least <strong>one special</strong>{" "}
                    character - @$!%*?&.
                  </p>
                  <p>
                    3. Must contain at least <strong>one uppercase</strong>{" "}
                    alphabet.
                  </p>
                  <p>
                    3. Should not contain your Name/DOB/Name of any familiar
                    person.
                  </p>
                  <p>3. Should not be a part or same as your username.</p>
                  <p>3. Should not be a dictionary word.</p>
                </div>
              </Card>
            </Col>
          </Row>
        </Modal>
      </Sider>
      {/* <ConfettiExplosion active={confettiVisible} /> */}

      <Layout>
        <Header
          style={{
            position: "fixed",
            width: "100%",
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between",
            background: menuColorInvert,
            borderBottomLeftRadius: "-20px",
          }}
        // style={{
        //   position: 'fixed', // Fix the header to the top of the viewport
        //   width: '100%', // Make it full-width
        //   zIndex: 1, // Add a zIndex to keep it above other elements
        //   display: 'flex',
        //   justifyContent: 'space-between',
        //   // background: 'dark'
        //   background: menuColor
        // }}
        >
          {/* <Header
          style={{
            // position: 'fixed',
            top: 0,
            // width: 'calc(100% - 220px)', // Adjusted width for the header
            height: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            background: 'dark'
          }}
        > */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px", color: "white" }}>
              <bold>{page}</bold>
            </span>
          </div>
          <div>
            {collapsed && (
              <img
                src={logo_rounded}
                alt="Logo"
                style={{
                  height: 48,
                  marginRight: "48px",
                  marginTop: "10px",
                }}
              />
            )}
          </div>
        </Header>

        {/* <Header
          style={{
            display: 'flex',
            justifyContent: 'flex-end', // Place logo to the right
            padding: '0 24px', // Add horizontal padding
            background: colorBgContainer,
            zIndex: 1,
            overflow: 'hidden'
          }}
        >
          <div>
            {collapsed && (
              <img
                src={logo_rounded}
                alt='Logo'
                style={{
                  height: 48,
                  marginLeft: '8px',
                  marginTop: '10px'
                  // zIndex: 20
                  // borderRadius: '20%' // Add this line to make the corners rounded
                }}
              />
            )}
          </div> */}

        {/* <Button
            type='text'
            icon={
              collapsed ? (
                <MenuUnfoldOutlined
                // style={{
                //   fontSize: '10px',
                //   color: '#08c',
                //   height: '10%',
                //   width: '40%'
                // }}
                />
              ) : (
                <MenuFoldOutlined />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 24,
              height: 35
            }}
          /> */}
        {/* <div style={{ marginLeft: '10px', color: 'black' }}>
            {currentPage}
          </div> */}
        {/* </Header> */}
        {/* <Content
          style={{
            margin: '64px 6px 0 0', // Adjusted the top margin to accommodate the fixed header
            // padding: 24,
            minHeight: 280,
            height: '100vh',
            background: '#f8f8f8'
            // background: colorBgContainer
          }}
          // style={{
          //   margin: '15px 6px',
          //   marginTop: '40px',
          //   padding: 24,
          //   minHeight: 280,
          //   background: colorBgContainer
          // }}
        > */}
        {/* <Watermark content='Ant Design'>
            <div
              style={{
                height: 500
              }}
            />
          </Watermark> */}
        <Content
          style={{
            position: "relative",
            margin: "64px 0px 0 0",
            minHeight: 280,
            height: "100vh",
            // backgroundImage: `url(${Diwali_Final})`, // Set the image as a background
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            // opacity: 0.2,
            backgroundColor: "rgba(255, 255, 255, 0.2)", // Add opacity (0.9 = 90% opaque)
          }}
        >
          {children}
        </Content>
        {/* <Content className='content-ant'>{children}</Content> */}
        {/* <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${diwali_new}) no-repeat center center fixed`,
            backgroundSize: 'cover',
            opacity: 0.1, // Adjust the opacity as needed
            pointerEvents: 'none' // Allow interaction with the content behind
          }}
        /> */}
      </Layout>
    </Layout>
  );
}

export default Main;
