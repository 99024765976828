import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from '../apiConfig'

export const fetchUserDetails = createAsyncThunk(
    'fetchUserDetails',
    async ({ jwtBearer, emp_code }) => {
        const response = await fetch(`${BASE_URL}admin/get/user/details/?emp_code=${emp_code}`, {
            headers: {
                Authorization: `Bearer ${jwtBearer}` // Include the bearer token in the request header
            }
        })
        return response.json()
    }
)

const userDetailsSlice = createSlice({
    name: 'userDetailsSlice',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: builder => {
        builder.addCase(fetchUserDetails.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchUserDetails.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        })
    }
})

export default userDetailsSlice.reducer
